import {Button, Col, Row} from "antd";
import {Pagination} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react";
import Programs1 from "../Assests/programs-01.png";
import Programs2 from "../Assests/programs-02.png";
import Programs3 from "../Assests/programs-03.png";
import Programs4 from "../Assests/programs-04.png";
import Programs5 from "../Assests/programs-05.png";
import Programs6 from "../Assests/programs-06.png";
import Programs7 from "../Assests/programs-07.png";
import Programs8 from "../Assests/programs-08.png";
import Programs9 from "../Assests/programs-09.png";
import {useNavigate} from "react-router-dom";

const OurProgrammesSection = () => {
    const programs = [
        {
            label: "Weight Loss",
            value: "weight-loss-program",
            img: Programs1
        },
        {
            label: "Diabetes Management",
            value: "diabetes-management-program",
            img: Programs2
        },
        {
            label: "PCOS",
            value: "pcod-pcos-diet-management",
            img: Programs3
        },
        {
            label: "Nutrition for Sports",
            value: "nutrition-for-sports",
            img: Programs4
        },
        {
            label: "Skin and Hair",
            value: "skin-and-hair-program",
            img: Programs5
        },
        {
            label: "Nutrition for Kids",
            value: "nutrition-for-kids",
            img: Programs6
        },
        {
            label: "Nutrition for new life",
            value: "nutrition-for-new-life",
            img: Programs7
        },
        {
            label: "Nutrition for Weight gain",
            value: "nutrition-for-weight-gain",
            img: Programs8
        },
        {
            label: "Blood test",
            value: "blood-test-interpretation",
            img: Programs9
        },
    ]
    return (
        <div className={"our-programs-main-container"}>
            <Row className={"heading-row"}>
                <Col lg={24} xs={24} className={"primary-heading flex-center"}>
                    <hr className={"backdrop-line"} />
                    <span className={"heading-text"}>Check Out Our Offers</span>
                </Col>
                <Col lg={24} xs={24} className={"secondary-heading flex-center"}>
                    Our Programs
                </Col>
            </Row>
            <div className={"swiper-container"}>
                <Swiper
                    className="programs-container"
                    loop={true}
                    grabCursor={true}
                    spaceBetween={24}
                    pagination={{
                        clickable: true
                    }}
                    autoplay={{delay: 3000}}
                    breakpoints={{
                        576: {
                            slidesPerView: 3
                        },
                        768: {
                            slidesPerView: 2,
                            spaceBetween: 48
                        },
                        1024: {
                            slidesPerView: 2,
                            spaceBetween: 48
                        },
                        1400: {
                            slidesPerView: 3,
                            spaceBetween: 48
                        }
                    }}
                    modules={[Pagination]}
                >
                    {programs?.map(({img, label, value}, idx) => {
                        return (
                            <SwiperSlide key={idx}>
                                <div style={{width: "100%", height: "100%"}}>
                                    <ProgramCard heading={label} image={img} url={value}/>
                                </div>
                            </SwiperSlide>
                        )
                    })}
                </Swiper>
            </div>
        </div>
    )
}

const ProgramCard = ({image, heading, url}) => {
    const navigate = useNavigate()
    return (
        <div className={"program-card-main-container"}>
            <Row className={"content-row"}>
                <Col lg={24} xs={24} className={"flex-center"}>
                    <img src={image} alt={"program"} className={"program-image"}/>
                </Col>
                <Col lg={24} xs={24} className={"primary-heading flex-center"}>
                    {heading}
                </Col>
                <Col lg={24} xs={24} className={"secondary-heading flex-center"}>
                    Simple & nutritious
                </Col>
                <Col lg={24} xs={24} className={"flex-center"}>
                    <Button
                        className={"know-more-button flex-center"}
                        onClick={() => navigate(`/program-detail/${url}`)}
                    >
                        Know More
                    </Button>
                </Col>
            </Row>
        </div>
    )
}

export default OurProgrammesSection