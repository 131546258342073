import {Button, Checkbox, Col, Form, Input, message, Row} from "antd";
import Cookies from "js-cookie";
import axios from "axios";
import {useEffect} from "react";

const LeaveReply = () => {
    const {TextArea} = Input
    const [replyForm] = Form.useForm()
    useEffect(() => {
        const formValues = Cookies.get("reply-form")
        if (formValues) {
            const parsedValues = JSON.parse(formValues)
            replyForm.setFieldsValue({...parsedValues})
        }
    }, [])
    const handleSubmit = async (values) => {
        try {
            if (values["should-save-cookie"]) {
                Cookies.set("reply-form", JSON.stringify({
                    name: values?.name,
                    email: values?.email,
                    website: values?.website
                }))
            }
            const data = {
                name: values?.name,
                email: values?.email,
                message: values?.comment,
                program: values?.website
            }
            const response = await axios.post("http://moderneraweb.com:9090/send-mail", data)
            message.success(response?.data?.message)
        } catch ({response}) {
            message.error(response?.data?.error)
        }
    }
    return (
        <div className={"leave-reply-main-container"}>
            <div className={"headings-container"}>
                <Row>
                    <Col lg={24} xs={24} className={"primary-heading"}>
                        Leave a Reply
                        <hr className={"horizontal-line"}/>
                    </Col>
                    <Col lg={24} xs={24} className={"secondary-heading"}>
                        Your email address will not be published. Required fields are marked *
                    </Col>
                </Row>
                <Row>
                    <Col lg={24} md={24}>
                        <div className={"form-container"}>
                            <Form
                                onFinish={handleSubmit}
                                form={replyForm}
                            >
                                <Form.Item
                                    name={"comment"}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please enter comment",
                                        },
                                    ]}
                                >
                                    <TextArea
                                        rows={10}
                                        placeholder={"Comment"}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name={"name"}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please enter name",
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder={"Name*"}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name={"email"}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please enter email",
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder={"Email*"}
                                        type={"email"}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name={"website"}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please enter website",
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder={"Website"}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name={"should-save-cookie"}
                                    valuePropName={"checked"}
                                >
                                    <Checkbox>
                                        Save my name, email, and website in this browser for the next time I comment.
                                    </Checkbox>
                                </Form.Item>
                                <Form.Item>
                                    <Button
                                        htmlType={"submit"}
                                        className={"post-comment-button"}
                                    >
                                        Post Comment
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default LeaveReply