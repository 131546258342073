import React, {useEffect, useRef, useState} from "react"
import {Autoplay} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react";
import Img1 from "../Assests/Gallery/img1.jpeg";
import Img2 from "../Assests/Gallery/img2.jpeg";
import Img3 from "../Assests/Gallery/img3.jpeg";
import Img4 from "../Assests/Gallery/img4.jpeg";
import Img5 from "../Assests/Gallery/img5.jpeg";
import Img6 from "../Assests/Gallery/img6.jpeg";
import Img7 from "../Assests/Gallery/img7.jpeg";
import Img8 from "../Assests/Gallery/img8.jpeg";
import Img9 from "../Assests/Gallery/img9.jpeg";
import Img10 from "../Assests/Gallery/img10.jpeg";
import Img11 from "../Assests/Gallery/img11.jpeg";
import Img12 from "../Assests/Gallery/img12.jpeg";
import Img13 from "../Assests/Gallery/img13.jpeg";
import Img14 from "../Assests/Gallery/img14.jpeg";
import Img15 from "../Assests/Gallery/img15.jpeg";
import Img16 from "../Assests/Gallery/img16.jpeg";
import Img17 from "../Assests/Gallery/img17.jpeg";
import Img18 from "../Assests/Gallery/img18.jpeg";
import Img19 from "../Assests/Gallery/img19.jpeg";
import Img20 from "../Assests/Gallery/img20.jpeg";
import Img21 from "../Assests/Gallery/img21.jpeg";
import Img22 from "../Assests/Gallery/img22.jpeg";
import Img23 from "../Assests/Gallery/img23.jpeg";
import Img24 from "../Assests/Gallery/img24.jpeg";
import Img25 from "../Assests/Gallery/img25.jpeg";
import Img26 from "../Assests/Gallery/img26.jpeg";
import Img27 from "../Assests/Gallery/img27.jpeg";
import Img28 from "../Assests/Gallery/img28.jpeg";
import Img29 from "../Assests/Gallery/img29.jpeg";
import Img30 from "../Assests/Gallery/img30.jpeg";
import Img31 from "../Assests/Gallery/img31.jpeg";
import Img32 from "../Assests/Gallery/img32.jpeg";
import Vid1 from "../Assests/Gallery/vid1.mp4";
import Vid2 from "../Assests/Gallery/vid2.mp4";
import Vid3 from "../Assests/Gallery/vid3.mp4";
import Vid4 from "../Assests/Gallery/vid4.mp4";
import Vid5 from "../Assests/Gallery/vid5.mp4";
import Vid6 from "../Assests/Gallery/vid6.mp4";
import Vid7 from "../Assests/Gallery/vid7.mp4";
import Vid8 from "../Assests/Gallery/vid8.mp4";
import Vid9 from "../Assests/Gallery/vid9.mp4";
import Vid10 from "../Assests/Gallery/vid10.mp4";
import {Modal} from "antd";
import {
    CloseCircularIcon,
    CloseIcon,
    CloseWithoutBorderIcon,
    RightArrowIcon,
    RightArrowWithoutCircleIcon
} from "../Utils/svgs";
import {useNavigate} from "react-router-dom";

const GalleryPage = () => {
    const [showImagesModal, setShowImagesModal] = useState(false)
    const [selectedImageSlide, setSelectedImageSlide] = useState(null)
    const modalSwiperRef = useRef(null)
    const videoContainerRef = useRef(null)
    const videoItemRef = useRef(null)
    const [reachedMaxScroll, setReachedMaxScroll] = useState(false);
    const [reachedZeroScroll, setReachedZeroScroll] = useState(true);
    const navigate = useNavigate()
    const swiper1Ref = useRef(null);
    const swiper2Ref = useRef(null);
    const swiper3Ref = useRef(null);
    const swiper4Ref = useRef(null);
    const swiper5Ref = useRef(null);
    const swiper6Ref = useRef(null);
    const swiper7Ref = useRef(null);
    const swiperRefs = [
        {
            ref: swiper1Ref,
            initialDelay: 1000,
        },
        {
            ref: swiper2Ref,
            initialDelay: 1500,
        },
        {
            ref: swiper3Ref,
            initialDelay: 1800,
        },
        {
            ref: swiper4Ref,
            initialDelay: 2000,
        },
        {
            ref: swiper5Ref,
            initialDelay: 1300,
        },
        {
            ref: swiper6Ref,
            initialDelay: 1200,
        },
        {
            ref: swiper7Ref,
            initialDelay: 1500,
        },
    ]
    const imagesArray1 = [
        {
            image: Img22
        },
        {
            image: Img20
        },
        {
            image: Img10
        },
        {
            image: Img14
        },
    ]
    const imagesArray2 = [
        {
            image: Img18
        },
        {
            image: Img30
        },
        {
            image: Img31
        },
        {
            image: Img27
        },
        {
            image: Img23
        },
    ]
    const imagesArray3 = [
        {
            image: Img1
        },
        {
            image: Img3
        },
        {
            image: Img4
        },
        {
            image: Img5
        },
    ]
    const imagesArray4 = [
        {
            image: Img32
        },
        {
            image: Img15
        },
        {
            image: Img16
        },
        {
            image: Img13
        },
    ]
    const imagesArray5 = [
        {
            image: Img6
        },
        {
            image: Img7
        },
        {
            image: Img8
        },
        {
            image: Img9
        },
        {
            image: Img19
        },
    ]
    const imagesArray6 = [
        {
            image: Img2
        },
        {
            image: Img21
        },
        {
            image: Img24
        },
        {
            image: Img28
        },
        {
            image: Img17
        },
    ]
    const imagesArray7 = [
        {
            image: Img11
        },
        {
            image: Img12
        },
        {
            image: Img25
        },
        {
            image: Img26
        },
        {
            image: Img29
        },
    ]
    const mobileImagesArray1 = [...imagesArray3]
    const mobileImagesArray2 = [...imagesArray7, ...imagesArray5]
    const mobileImagesArray3 = [...imagesArray1, ...imagesArray2, ...imagesArray6]
    const mobileImagesArray4 = [...imagesArray4]
    const allImages = [
        ...imagesArray1,
        ...imagesArray2,
        ...imagesArray3,
        ...imagesArray4,
        ...imagesArray5,
        ...imagesArray6,
        ...imagesArray7
    ]

    useEffect(() => {
        // Autoplay for Swiper 1
        const startAutoplay = (swiperRef, delay) => {
            if (swiperRef?.current && swiperRef?.current?.swiper) {
                swiperRef?.current?.swiper?.autoplay?.stop();
            }
            setTimeout(() => {
                if (swiperRef?.current && swiperRef?.current?.swiper) {
                    swiperRef?.current?.swiper.autoplay.start();
                }
            }, delay);
        };
        swiperRefs?.forEach((item) => {
            startAutoplay(item?.ref, item?.initialDelay)
        })
    }, []);

    useEffect(() => {
        const container = videoContainerRef.current;

        const handleScroll = () => {
            const maxScroll = container.scrollWidth - container.clientWidth;
            const currentScroll = container.scrollLeft;

            if (currentScroll >= maxScroll) {
                setReachedMaxScroll(true);
            } else {
                setReachedMaxScroll(false);
            }

            if (currentScroll === 0) {
                setReachedZeroScroll(true)
            } else {
                setReachedZeroScroll(false)
            }
        };

        container?.addEventListener('scroll', handleScroll);

        return () => {
            container?.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const selectImage = (image) => {
        const index = allImages.findIndex((item) => item.image === image)
        // setSelectedImageSlide(index)
        setShowImagesModal(true)
        // modalSwiperRef?.current?.swiper?.slideTo(index, 2000, () => {
        //     console.log("finished")
        // })
    }

    const scrollContainer = (x) => {
        if (videoContainerRef.current) {
            videoContainerRef.current.scroll({
                top: 0,
                left: videoContainerRef.current.scrollLeft + x,
                behavior: 'smooth'
            })
        }
    };

    return (
        <div className={"gallery-page-main-container"}>
            <div className={"headings-container flex-center"}>
                <div className={"primary-heading"}>
                    Gallery
                </div>
                <div className={"secondary-heading"}>
                    <span
                        className={"home-page-link"}
                        onClick={() => navigate("/")}
                    >
                        Home
                    </span> / Gallery
                </div>
            </div>
            <div className={"image-section-wrapper d-flex"}>
                <div className={"gallery"}>
                    <div className={"gallery-item gallery-item-1"}>
                        <Swiper
                            ref={swiper1Ref}
                            className="images-container"
                            loop={true}
                            speed={1000}
                            grabCursor={true}
                            spaceBetween={24}
                            autoplay={{delay: 3000, disableOnInteraction: false}}
                            modules={[Autoplay]}
                        >
                            {imagesArray1?.map(({image}, idx) => {
                                return (
                                    <SwiperSlide key={idx}>
                                        <div style={{width: "100%", height: "100%"}} onClick={() => selectImage(image)}>
                                            <img src={image} alt="" className="carousel-image"/>
                                        </div>
                                    </SwiperSlide>
                                )
                            })}
                        </Swiper>
                    </div>
                    <div className={"gallery-item gallery-item-2"}>
                        <Swiper
                            ref={swiper2Ref}
                            className="images-container"
                            loop={true}
                            speed={1000}
                            grabCursor={true}
                            spaceBetween={24}
                            autoplay={{delay: 3157, disableOnInteraction: false}}
                            modules={[Autoplay]}
                        >
                            {imagesArray2?.map(({image}, idx) => {
                                return (
                                    <SwiperSlide key={idx}>
                                        <div style={{width: "100%", height: "100%"}} onClick={() => selectImage(image)}>
                                            <img src={image} alt="" className="carousel-image"/>
                                        </div>
                                    </SwiperSlide>
                                )
                            })}
                        </Swiper>
                    </div>
                    <div className={"gallery-item gallery-item-3"}>
                        <Swiper
                            ref={swiper3Ref}
                            className="images-container"
                            loop={true}
                            speed={1000}
                            grabCursor={true}
                            spaceBetween={24}
                            autoplay={{delay: 3389, disableOnInteraction: false}}
                            modules={[Autoplay]}
                        >
                            {imagesArray3?.map(({image}, idx) => {
                                return (
                                    <SwiperSlide key={idx}>
                                        <div style={{width: "100%", height: "100%"}} onClick={() => selectImage(image)}>
                                            <img src={image} alt="" className="carousel-image"/>
                                        </div>
                                    </SwiperSlide>
                                )
                            })}
                        </Swiper>
                    </div>
                    <div className={"gallery-item gallery-item-4"}>
                        <Swiper
                            ref={swiper4Ref}
                            className="images-container"
                            loop={true}
                            speed={1000}
                            grabCursor={true}
                            spaceBetween={24}
                            autoplay={{delay: 3819, disableOnInteraction: false}}
                            modules={[Autoplay]}
                        >
                            {imagesArray4?.map(({image}, idx) => {
                                return (
                                    <SwiperSlide key={idx}>
                                        <div style={{width: "100%", height: "100%"}} onClick={() => selectImage(image)}>
                                            <img src={image} alt="" className="carousel-image"/>
                                        </div>
                                    </SwiperSlide>
                                )
                            })}
                        </Swiper>
                    </div>
                    <div className={"gallery-item gallery-item-5"}>
                        <Swiper
                            ref={swiper5Ref}
                            className="images-container"
                            loop={true}
                            speed={1000}
                            grabCursor={true}
                            spaceBetween={24}
                            autoplay={{delay: 3403, disableOnInteraction: false}}
                            modules={[Autoplay]}
                        >
                            {imagesArray5?.map(({image}, idx) => {
                                return (
                                    <SwiperSlide key={idx}>
                                        <div style={{width: "100%", height: "100%"}} onClick={() => selectImage(image)}>
                                            <img src={image} alt="" className="carousel-image"/>
                                        </div>
                                    </SwiperSlide>
                                )
                            })}
                        </Swiper>
                    </div>
                    <div className={"gallery-item gallery-item-6"}>
                        <Swiper
                            ref={swiper6Ref}
                            className="images-container"
                            loop={true}
                            speed={1000}
                            grabCursor={true}
                            spaceBetween={24}
                            autoplay={{delay: 3720, disableOnInteraction: false}}
                            modules={[Autoplay]}
                        >
                            {imagesArray6?.map(({image}, idx) => {
                                return (
                                    <SwiperSlide key={idx}>
                                        <div style={{width: "100%", height: "100%"}} onClick={() => selectImage(image)}>
                                            <img src={image} alt="" className="carousel-image"/>
                                        </div>
                                    </SwiperSlide>
                                )
                            })}
                        </Swiper>
                    </div>
                    <div className={"gallery-item gallery-item-7"}>
                        <Swiper
                            ref={swiper7Ref}
                            className="images-container"
                            loop={true}
                            speed={1000}
                            grabCursor={true}
                            spaceBetween={24}
                            autoplay={{delay: 3555, disableOnInteraction: false}}
                            modules={[Autoplay]}
                        >
                            {imagesArray7?.map(({image}, idx) => {
                                return (
                                    <SwiperSlide key={idx}>
                                        <div style={{width: "100%", height: "100%"}} onClick={() => selectImage(image)}>
                                            <img src={image} alt="" className="carousel-image"/>
                                        </div>
                                    </SwiperSlide>
                                )
                            })}
                        </Swiper>
                    </div>
                </div>
                <div className={"gallery-mobile"}>
                    <div className={"gallery-item gallery-item-1"}>
                        <Swiper
                            ref={swiper1Ref}
                            className="images-container"
                            loop={true}
                            speed={1000}
                            grabCursor={true}
                            spaceBetween={24}
                            autoplay={{delay: 3000, disableOnInteraction: false}}
                            modules={[Autoplay]}
                        >
                            {mobileImagesArray1?.map(({image}, idx) => {
                                return (
                                    <SwiperSlide key={idx}>
                                        <div style={{width: "100%", height: "100%"}} onClick={() => selectImage(image)}>
                                            <img src={image} alt="" className="carousel-image"/>
                                        </div>
                                    </SwiperSlide>
                                )
                            })}
                        </Swiper>
                    </div>
                    <div className={"gallery-item gallery-item-2"}>
                        <Swiper
                            ref={swiper2Ref}
                            className="images-container"
                            loop={true}
                            speed={1000}
                            grabCursor={true}
                            spaceBetween={24}
                            autoplay={{delay: 3157, disableOnInteraction: false}}
                            modules={[Autoplay]}
                        >
                            {mobileImagesArray2?.map(({image}, idx) => {
                                return (
                                    <SwiperSlide key={idx}>
                                        <div style={{width: "100%", height: "100%"}} onClick={() => selectImage(image)}>
                                            <img src={image} alt="" className="carousel-image"/>
                                        </div>
                                    </SwiperSlide>
                                )
                            })}
                        </Swiper>
                    </div>
                    <div className={"gallery-item gallery-item-3"}>
                        <Swiper
                            ref={swiper3Ref}
                            className="images-container"
                            loop={true}
                            speed={1000}
                            grabCursor={true}
                            spaceBetween={24}
                            autoplay={{delay: 3389, disableOnInteraction: false}}
                            modules={[Autoplay]}
                        >
                            {mobileImagesArray3?.map(({image}, idx) => {
                                return (
                                    <SwiperSlide key={idx}>
                                        <div style={{width: "100%", height: "100%"}} onClick={() => selectImage(image)}>
                                            <img src={image} alt="" className="carousel-image"/>
                                        </div>
                                    </SwiperSlide>
                                )
                            })}
                        </Swiper>
                    </div>
                    <div className={"gallery-item gallery-item-4"}>
                        <Swiper
                            ref={swiper4Ref}
                            className="images-container"
                            loop={true}
                            speed={1000}
                            grabCursor={true}
                            spaceBetween={24}
                            autoplay={{delay: 3819, disableOnInteraction: false}}
                            modules={[Autoplay]}
                        >
                            {mobileImagesArray4?.map(({image}, idx) => {
                                return (
                                    <SwiperSlide key={idx}>
                                        <div style={{width: "100%", height: "100%"}} onClick={() => selectImage(image)}>
                                            <img src={image} alt="" className="carousel-image"/>
                                        </div>
                                    </SwiperSlide>
                                )
                            })}
                        </Swiper>
                    </div>
                </div>
            </div>
            <div className={"video-section-wrapper d-flex"}>
                <div className={"arrow-container"}>
                    <div className={"inner-container d-flex"} ref={videoContainerRef}>
                        <div className={"video-item"}>
                            <video src={Vid6} controls={true} className={"video-content"}/>
                        </div>
                        <div className={"video-item"}>
                            <video src={Vid10} controls={true} className={"video-content"}/>
                        </div>
                        <div className={"video-item"}>
                            <video src={Vid7} controls={true} className={"video-content"}/>
                        </div>
                        <div className={"video-item"} ref={videoItemRef}>
                            <video src={Vid1} controls={true} className={"video-content"}/>
                        </div>
                        <div className={"video-item"}>
                            <video src={Vid2} controls={true} className={"video-content"}/>
                        </div>
                        <div className={"video-item"}>
                            <video src={Vid3} controls={true} className={"video-content"}/>
                        </div>
                        <div className={"video-item"}>
                            <video src={Vid4} controls={true} className={"video-content"}/>
                        </div>
                        <div className={"video-item"}>
                            <video src={Vid5} controls={true} className={"video-content"}/>
                        </div>
                        <div className={"video-item"}>
                            <video src={Vid8} controls={true} className={"video-content"}/>
                        </div>
                        <div className={"video-item"}>
                            <video src={Vid9} controls={true} className={"video-content"}/>
                        </div>
                    </div>
                    {!reachedMaxScroll &&
                        <RightArrowIcon className={"right-arrow get-hover"}
                                        onClick={() => scrollContainer(videoItemRef?.current?.offsetWidth)}
                                        fill={"#000000"}
                        />
                    }
                    {!reachedZeroScroll &&
                        <RightArrowIcon className={"left-arrow get-hover"}
                                        onClick={() => scrollContainer(-videoItemRef?.current?.offsetWidth)}
                                        fill={"#000000"}
                        />
                    }
                </div>
            </div>
            <Modal
                open={showImagesModal}
                footer={false}
                closable={false}
                className={"images-carousel-modal"}
            >
                <div>
                <Swiper
                    ref={modalSwiperRef}
                    loop={true}
                    speed={1000}
                    grabCursor={true}
                    spaceBetween={24}
                    className={"images-container"}
                >
                    {allImages?.map(({image}, idx) => {
                        return (
                            <SwiperSlide key={idx}>
                                <div>
                                    <img src={image} alt="" className="carousel-image"/>
                                </div>
                            </SwiperSlide>
                        )
                    })}
                </Swiper>
                </div>
                <RightArrowWithoutCircleIcon className={"right-arrow get-hover"} fill={"#ffffff"} onClick={() => {
                    modalSwiperRef?.current?.swiper?.slideNext(1000)
                }}/>
                <RightArrowWithoutCircleIcon className={"left-arrow get-hover"} fill={"#ffffff"}
                                onClick={() => modalSwiperRef?.current?.swiper?.slidePrev(1000)}/>
                <CloseWithoutBorderIcon className={"close-icon get-hover"} onClick={() => setShowImagesModal(false)}
                                   fill={"#ffffff"}/>
            </Modal>
        </div>
    )
}

export default GalleryPage