import {Col, Row} from "antd";
import React from "react";
import {FacebookIcon, InstagramIcon, LinkedInIcon} from "../Utils/svgs";

const Footer = () => {
    const socialMediaIcons = [
        {
            name: "facebook",
            icon: <FacebookIcon fill={"#ffffff"} className={"social-media-icon"}/>,
            link: "https://www.facebook.com/tenzinnutrition"
        },
        {
            name: "linkedIn",
            icon: <LinkedInIcon fill={"#ffffff"} className={"social-media-icon"}/>,
            link: "https://www.linkedin.com/in/tenzin-kelsang-dietitian-1078b2136/"
        },
        {
            name: "instagram",
            icon: <InstagramIcon fill={"#ffffff"} className={"social-media-icon"}/>,
            link: "https://instagram.com/tenzinnutrition?igshid=ZGUzMzM3NWJiOQ=="
        },
    ]

    return (
        <div className={"footer-main-container"}>
            <Row className={"inner-content-row"} gutter={[0, 20]}>
                <Col lg={24} xs={24} className={"flex-center"}>
                    <Row className={"social-icons-container"} align={"middle"} gutter={6}>
                        {socialMediaIcons?.map((item, idx) => (
                            <Col>
                        <span
                            className={"icon-container flex-center get-hover"}
                            onClick={() => window.open(item?.link, '_blank')}
                        >
                            {item?.icon}
                        </span>
                            </Col>
                        ))}
                    </Row>
                </Col>
                <Col lg={24} xs={24} md={24} className={"flex-center rights-reserved"}>
                    <span>
                        © Tenzine Nutrition 2021-2022. All rights reserved.
                    </span>
                </Col>
                <Col lg={24} xs={24} md={24} className={"d-flex developer-website"}>
                    <span>
                        Designed and Developed By — ModernEraWeb
                    </span>
                </Col>
            </Row>
        </div>
    )
}

export default Footer