import React, {useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {programsList} from "../data/programsList";
import {Col, Row} from "antd";

const ProgramDetailPage = () => {
    const {id: programId} = useParams()
    const navigate = useNavigate()
    const breadCrumb = programId.split("-").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ");
    const currentProgram = programsList.find((item, idx) => item?.value === programId)
    const isHeadingWhite = programId === "pcod-pcos-diet-management" ||
        programId === "skin-and-hair-program" ||
        programId === "nutrition-for-new-life" ||
        programId === "blood-test-interpretation"
    const contentMap = {
        "weight-loss-program": (
            <>
                <p className={"mb-75"}>
                    Many people face challenges in their journey to lose weight. It is a misconception when people
                    consider
                    weight loss being instant. Consistency is key when it comes to eating healthy and physical exercise,
                    which are the main determinants of weight loss. In addition, it is critical to take into account the
                    variances of metabolism among individuals. A set of routines that may work for a person, may not
                    work
                    for another individual. This is when personalized diet plans make a difference. Depending on whether
                    your metabolism is slow or fast, you can lose weight if you are informed what to do
                </p>
                <ul>
                    <li className={"p-top-btm-10"}>
                        Counselling : Understand details of client’s medical condition, symptoms, and then strategize a
                        healthy
                        weight loss program
                    </li>
                    <hr className={"horizontal-line"}/>
                    <li className={"p-top-btm-10"}>
                        Balance diet approach strategy: Focus on healthy carbohydrates, fibre, protein, other nutrients
                        that include
                        Omega 3 fat, Magnesium, Zinc, Vitamin C, B complex, and Fat soluble vitamins
                    </li>
                    <hr className={"horizontal-line"}/>
                    <li className={"p-top-btm-10"}>
                        Blood report analysis: Addressing specific foods for deranged blood parameters
                    </li>
                    <hr className={"horizontal-line"}/>
                    <li className={"p-top-btm-10"}>
                        Body measurement
                    </li>
                    <hr className={"horizontal-line"}/>
                    <li className={"p-top-btm-10"}>
                        Addressing foods based on symptoms or other health
                    </li>
                    <hr className={"horizontal-line"}/>
                    <li className={"p-top-btm-10"}>
                        Plan seven-day customized or personalized diet plan
                    </li>
                    <hr className={"horizontal-line"}/>
                    <li className={"p-top-btm-10"}>
                        Consistent reporting and updates: Review progress and discuss challenges on a weekly basis
                    </li>
                </ul>
            </>
        ),
        "diabetes-management-program": (
            <>
                <p>
                    In 21st century, diabetes is one of the major disorder that has a high mortality risk. However, most
                    people think that diabetes can be cured by its medication. With my successful efforts in handling
                    numerous cases of diabetes in the past couple of years, it has made me realize how therapeutic diet
                    and lifestyle modification play a vital role in preventing the disease.
                </p>
                <p>
                    Let me give you a very simple example. There was a client who came to me and his HbA1 c level is
                    extremely high (HbA1c -12%), he was on diabetic medication as well as insulin for many years but
                    unfortunately, his sugar level never came down. Finally, his doctor suggested him to meet a
                    dietitian in order to change his eating habits. Upon referral, I took care of the counselling, and
                    planed a personalized nutrition plan which he followed for three months. The result was significant.
                    His sugar level dropped from 12% to 7%. Hence, his doctor reduced his insulin and other medications’
                    prescriptions. There’s a proverb that states – “When diet is wrong, medicine is of no use. When diet
                    is correct, medicine is of no need.” It posits that a healthy diet is very important to keep the
                    disease away
                </p>
                <p>
                    On the other hand, there are genuine cases of type 1 diabetes that requires a long term usage of
                    treatment and medicines. However, with effective lifestyle management strategies, it can be managed
                    well, ultimately reducing medicine dosages.
                </p>
                <p className={"fw-700"}>
                    The following is a comprehensive pre/diabetic dietary guidelines
                </p>
                <ul>
                    <li className={"p-top-btm-10"}>
                        Well-balanced diet (Carbohydrate, Protein, Fibre & Micronutrients) -Eating your well balanced
                        meals at about the same time every day helps stabilize your blood sugar
                    </li>
                    <hr className={"horizontal-line"}/>
                    <li className={"p-top-btm-10"}>
                        Portion control: Eating small, frequent meal helps regulate blood sugar and also portion control
                        is also important because eating large meals won’t help reduce blood sugar level
                    </li>
                    <hr className={"horizontal-line"}/>
                    <li className={"p-top-btm-10"}>
                        Low Glycaemic diet (low sugar diet) -All foods that we are consuming have sugar level but the
                        amount of sugar in each food is very different. There is nothing wrong if you are eating sugar
                        if you know what to eat, how much to eat, when to eat.
                    </li>
                    <hr className={"horizontal-line"}/>
                    <li className={"p-top-btm-10"}>
                        Carb counting – It is imperative to monitor how much carbohydrate is consumed on a daily basis
                        because it affects blood sugar the most!
                    </li>
                </ul>
            </>
        ),
        "pcod-pcos-diet-management": (
            <>
                <p>
                    Polycystic ovary syndrome (PCOS) is not a disease but it is an endocrine disorder. Women with PCOD
                    may
                    experience hirsutism, acne, irregular period, hormonal imbalance, infertility, endometrial cancer,
                    and
                    diabetes. Moreover, many women in this condition are overweight and obese. Abdominal obesity leads
                    to
                    what is known as “Apple shape”. In fact, weight gain is one of the most common side effects of PCOS
                    and
                    PCOD
                </p>
                <p>
                    Importantly, effective approaches of nutrition such as balanced diet, high protein, complex
                    carbohydrate with
                    exercise improve endocrine features, reproductive function and lowers cardiometabolic risk profile,
                    insulin, and
                    reduces many of the physical symptoms of PCOS.
                </p>
                <p>
                    Weight management through therapeutic diet and lifestyle modifications are fundamental to subsite
                    further complications
                </p>
                <p>
                    It is really true when Edward Stanley said, “those who think they have no time for healthy eating,
                    will sooner or later have to find time for illness.” Therefore, don’t ignore your health.
                </p>
                <ul>
                    <li className={"p-top-btm-10"}>
                        Well-balanced diet (Carbohydrate, Protein, Fibre & Micronutrients) -Eating your well balanced
                        meals at about the same time every day helps stabilize your blood sugar
                    </li>
                    <hr className={"horizontal-line"}/>
                    <li className={"p-top-btm-10"}>
                        Portion control: Eating small, frequent meal helps regulate blood sugar and also portion control
                        is also important because eating large meals won’t help reduce blood sugar level
                    </li>
                    <hr className={"horizontal-line"}/>
                    <li className={"p-top-btm-10"}>
                        Low Glycaemic diet (low sugar diet) -All foods that we are consuming have sugar level but the
                        amount of sugar in each food is very different. There is nothing wrong if you are eating sugar
                        if you know what to eat, how much to eat, when to eat.
                    </li>
                    <hr className={"horizontal-line"}/>
                    <li className={"p-top-btm-10"}>
                        Carb counting – It is imperative to monitor how much carbohydrate is consumed on a daily basis
                        because it affects blood sugar the most!
                    </li>
                </ul>
            </>
        ),
        "nutrition-for-sports": (
            <>
                <p>
                    Nutrition is imperative to everyone, especially prominent to athletes because it provides sources of
                    energy required for their best performances. The food that they eat impacts their strength,
                    training, performance, recovery, and overall health. Unfortunately, many athletes do not understand
                    the impact and importance of proper nutrition on their performance and health. Proper fuelling and
                    refuelling the body is essential to reach an individual’s full athletic potential.
                </p>
                <p>
                    In athletes, eating processed food in addition to having insufficient calories can be harmful as
                    they can reduce performances, recovery, stamina and can also lead to weight gain.
                </p>
                <p>
                    Whether you are a professional athlete or someone who simply enjoy working out, what and how much
                    you eat or drink can have a huge impact on your personal goals. In particular, diet plays a crucial
                    role in the enhancement of the performance, providing fuel to the muscles and body fluid
                    replacement. Sufficient energy should come from a variety of foods that provide enough
                    carbohydrates, protein, fat and micronutrients in order to prevent injuries and enhance one’s
                    performance. The proportions of protein and carbohydrates required will vary across individuals
                    considering factors such as age, weight, height, the intensity and the type of sport in order to
                    meet their performance goals
                </p>
                <p>
                    Therefore, nutrition is essential for athletes. Jack Lalanne once said that exercise is king and
                    nutrition is queen, put them together and you got a kingdom. Do not ignore what you are putting into
                    your body as your eating affects your performance and decides how recovery would be. Most
                    importantly, meals eaten before, during and after exercise are crucial for performance, stamina,
                    recovery, and prevention of injuries. As a general rule of thumb, athletes should eat two hours
                    before exercising and this meal should be high in carbohydrates, low in fat and low to moderate in
                    protein. Carbohydrates are the main source of energy that power your exercise regime and protein is
                    required to aid muscle growth and repair. After exercising, you need to replace carbohydrates you
                    have lost and you need to ensure proper muscle recovery by including proteins in your post-training
                    meal.
                </p>
                <p>
                    Here, my clinic will help you strategize workout meals to help develop performance, improve stamina,
                    prevent injury, control weight and improve overall health. I also analyze blood chemistry, sweat
                    analysis and also rectify and correct nutrient deficiency to prevent further complications.
                </p>
            </>
        ),
        "skin-and-hair-program": (
            <>
                <p>The common saying goes, ‘Beauty is in the eye of the beholder’</p>
                <p>
                    Everyone wants to look beautiful, and desires beautiful skin and healthy hair. Thus, we invest lots
                    of money in skin and hair products. However, the importance of eating healthy food, a big factor for
                    healthy skin, can be ignored.
                </p>
                <p>
                    Nutrition plays a vital role when it comes to skin and hair. Moreover, eating a variety of nutrients
                    is key to being healthy inside and out. In addition, specific macronutrients, vitamins, and minerals
                    can provide essential nutrients for healthy hair and skin.
                </p>
                <p>
                    Robert Urich once said that a healthy outside starts from the Inside. Therefore, eating a well-
                    balanced meal that nourishes one’s body is important. My diet clinic is in a reputable position to
                    help you discover your eating capacity to help you see the best version of your self with healthy
                    hair and beautiful skin.
                </p>
                <p className={"fw-700"}>
                    For skin
                </p>
                <p>
                    Skin is one of the most important parts of your body and it is mostly made up of protein. Keratin,
                    collagen and elastin are proteins that keep the skin looking healthy as they provide strength and
                    elasticity. Processed food increases inflammation and results in nutrient deficiency. As a result,
                    skin issues like acne, pimple, rashes, pore, Psoriasis, vitiligo, and other skin allergy like
                    urticaria, eczema can occur. Therefore, nutrients like omega 3 fatty acid, iron, collagen, vitamin
                    C, vitamin A, antioxidant, vitamin E, zinc, vitamin B6 are essential to keep your skin healthy and
                    glowing
                </p>
                <p className={"fw-700"}>
                    For hair
                </p>
                <p>
                    Healthy food is also a causative factor for healthy hair
                </p>
                <p>
                    The speed of hair growth is dependent on genetics, gender, age, hormones. It may be reduced by
                    nutrient deficiency (i.e., protein, iron, biotin, vitamin B3, vitamin6, vitamin 5, vitamin B12,
                    vitamin C, zinc) and hormonal fluctuations (i.e., menopause, polycystic ovaries, thyroid disease).
                    These nutrients are believed to help strengthen hair follicles and cells, resulting in healthier
                    hair growth. Moreover, they also help remove dead skin from the scalp, enhancing new growth.
                    Therefore, it is crucial for everyone to eat a well-balanced diet to have healthy and beautiful
                    hair. Hydration is also equally important
                </p>
            </>
        ),
        "nutrition-for-kids": (
            <>
                <p>
                    Nutrition is essential for every age but it has become one of the prominent elements for kids as it
                    is directly linked to their overall growth and development. Having the right nutrition at the right
                    age is imperative to lead a healthier life. It is obvious that a proper guide of nutritional diet
                    for the kids will lead to better mental and physical health. Good nutrition is vital for cognitive
                    development and therefore, educational success.
                </p>
                <p>
                    When kids don’t adopt the habit of eating healthy food when they are young, it is very difficult to
                    change their eating habits as they become addicted to highly palatable diet
                </p>
                <p>
                    The essential nutrients that are required for kids’ growth, brain development and overall health are
                    Omega 3 fatty acid, protein, good fat, vitamins, minerals, proper carbohydrates, and antioxidants
                </p>
                <p>
                    People may not realize just how important diet is for the development of the brain and the body, and
                    how it impacts behaviour in children. The brain requires certain nutrients in order to develop
                    properly in children. If a child does not receive these vital nutrients from the food they eat, they
                    will experience diet deficiency and their associated negative health impact. For example,
                    deficiencies in iron lead to learning difficulties in later developmental stages
                </p>
                <p>
                    Such deficiency adversely affects behaviors, physical growth and immunity, cognitive development
                    including intelligence, memory, risks of obesity, and performances in sports.
                </p>
                <p>
                    As parents, it is one’s duty to teach them how to eat healthy food and be cautious about their diet.
                    Consulting with a professional nutritionist is highly recommended. Unfortunately, highly processed
                    food is being sold widely and has become easily accessible today. These processed foods do not
                    provide adequate amounts of nutrients. Hence, mental development is affected as well as the physical
                    body, due to the lack of nutrition
                </p>
                <p>
                    As a professional nutritionist, my job is to help transform lives by providing a customized
                    nutrition plan for kids, designed in a way that follows his/her specific activity, lifestyle,
                    culture, taste and liking. Ann Wigmore once said, “The food you eat can either be the safest & most
                    powerful form of medicine or the slowest form of poison.”
                </p>
            </>
        ),
        "nutrition-for-new-life": (
            <>
                <p>
                    This phase of life in every woman is most important that should be taken extra care of in all
                    aspects of their life.
                </p>
                <p>
                    Nutrition is a very important factor in a healthy life and for a healthy baby. And if a woman has
                    above nutrition deficiency there is a defect in the birth. So the women must assess their balanced
                    nutritional status before getting conceived or early weeks of the pregnancy
                </p>
                <p>
                    The women who are not having balanced nutritional status can affect in various cases of
                    pre-pregnancy and post-pregnancy.
                </p>
                <p>
                    If the women do not have a balanced nutrition status during the time that they are likely to
                    conceive can be affected by a lack of nourishment and being underweight. Those who try to get a
                    balanced nutrition status during pregnancy can be weakened because of more requirements from the
                    body for the growth of the baby.
                </p>
                <p>
                    And the inadequacy of balanced nutrition status before getting conceive will affect the whole
                    ongoing process of the pregnancy period. And it also impacts the baby in healthy growth and birth
                    weight.
                </p>
                <p>
                    Now, Under lactation, insufficient nutrients lead to a lack of milk production so lactating requires
                    a lot of energy that will be supplied from the nutrients and it is also very important for your
                    health and well-being in the whole process of pregnancy.
                </p>
                <p>
                    Nutrition is just having healthy food, a balanced diet and consuming essential nutrients like iron,
                    vitaminB12, folic acid and omega 3 fatty acids and they are key requirements for the healthy
                    development and growth of a child’s brain.
                </p>
                <p>
                    And everyone should understand the importance of nutrition in life and that must be known according
                    to your body needs and conditions.
                </p>
                <p>
                    So consulting a nutritionist helps you in understanding your body needs and in getting a proper diet
                    plan for yourself.
                </p>
                <p>
                    If you are one of them, then you are in the right place.
                </p>
                <p>
                    Yes, My clinic will help you in getting that balanced and healthy diet program and help you to get
                    the safest and healthy birth of the baby.
                </p>
            </>
        ),
        "nutrition-for-weight-gain": (
            <>
                <p>
                    There are people in the world who suffer from being overweight and obese. However, some people have
                    the
                    opposite problem of being skinny. The Study says that being underweight is more problematic than
                    being
                    overweight. Because it causes more health problems like infections, fertility problems, fatigue and
                    weakened immune system.
                </p>
                <p>
                    Also, there are other types of people who are naturally thin and healthy at the same time. And they
                    think that being skinny is not a problem but that’s not true
                </p>
                <p>
                    Now, The causes for weight loss might be thyroid, cancer, physical and mental illness and it might
                    also
                    be a genetic problem in some cases.
                </p>
                <p>
                    When it comes to weight gain, the best nutrition is protein, and following a balanced diet is very
                    important to have a consistent, sustainable and healthier way of weight gain. Now, People might
                    think
                    that gaining weight is simpler than losing it and they tend to gain weight by eating unhealthy fat.
                    So
                    it’s important to understand that gaining weight is not a short term goal, it’s a healthy lifestyle
                    that
                    must be adopted by every individual
                </p>
                <p>
                    To gain weight more healthily, one should have a regime that helps to follow it consistently
                </p>
                <p>
                    Now, everyone’s body type is different so to get that perfect routine that suits your body, you must
                    get
                    nutritional therapy which helps you in knowing your body conditions, needs and metabolism through
                    your
                    journey and which also ensures that it is realistic.
                </p>
                <p>
                    My clinic will help you to discover that regime and also helps in cultivating a healthy weight gain
                    program that gives you consistent and realistic results.
                </p>
            </>
        ),
        "blood-test-interpretation": (
            <>
                <p>
                    The blood tests are very imperative to determine nutrient deficiencies, certain diseases and
                    disorders such as PCOS, Thyroid, Diabetes etc. We don’t know how healthy the person is until and
                    unless he or she has done the blood test. Besides that, many people tend to use Nutritional
                    supplements without knowing their blood chemistry. The best way to consume supplements is to
                    understand your blood test and then decide which supplements you need to take under proper guidance.
                </p>
                <p>
                    When you have nutrient deficient such as
                </p>
                <ul>
                    <li className={"p-top-btm-10"}>
                        <span className={"fw-700"}>Vitamin D - </span>It affects growth for kids, Knee pain, reduce bone
                        mineral density, low immunity,
                        fracture, weight gain, etc.
                    </li>
                    <hr className={"horizontal-line"}/>
                    <li className={"p-top-btm-10"}>
                        <span className={"fw-700"}>Vitamin B12 - </span> Low stamina, poor concentration, and focus, low
                        haemoglobin
                        Anaemia, Fatigue, etc.
                    </li>
                    <hr className={"horizontal-line"}/>
                    <li className={"p-top-btm-10"}>
                        <span className={"fw-700"}>Calcium - </span>Calcium: Affect growth for kids, poor vitamin d
                        absorption, joint-related pain, reduce bone
                        mineral density, etc
                    </li>
                    <hr className={"horizontal-line"}/>
                    <li className={"p-top-btm-10"}>
                        <span className={"fw-700"}>Vitamin C - </span>Low immunity, Skin issues, slow in recovery
                    </li>
                </ul>
                <p>
                    On other hand, when you have a high level of
                </p>
                <ul>
                    <li className={"p-top-btm-10"}>
                        <span className={"fw-700"}>Uric acid - </span>It develops a condition called <b>gout</b> that
                        causes
                        painful joints that accumulate <b>urate</b> It can also make your blood and urine too acidic.
                    </li>
                    <hr className={"horizontal-line"}/>
                    <li className={"p-top-btm-10"}>
                        <span className={"fw-700"}>Cholesterol issues - </span> It increases the risk of heart disease,
                        and develop fatty deposits in your blood vessels.
                    </li>
                    <hr className={"horizontal-line"}/>
                    <li className={"p-top-btm-10"}>
                        <span className={"fw-700"}>Sugar level - </span>Prolong high level of sugar can damage the
                        vessels that supply blood to vital organs, which can increase the risk of heart disease and
                        stroke, kidney disease, vision problems, and nerve problems.
                    </li>
                </ul>
                <p>
                    When I am dealing with clients, I always suggest them to do the blood test and help them to rectify
                    each parameter through therapeutic diet and life style modification as saying goes
                </p>
                <p className={"fw-700"}>
                    Prevention is better than cure
                </p>
                <p>
                    Therefore, Once you have done a blood test, it is indispensable to seek advice from a doctor for
                    medical intervention AND a nutritionist to determine the changes you need to make to correct your
                    values
                </p>
            </>
        ),
        "holistic-health-program": (
            <>
                <p>
                    Holistic health is all about caring for yourself and the whole of you. According to your mental,
                    physical, emotional and spiritual illness. Holism is taking care of health and well-being in all
                    facets of life. And holistic nutrition is just eating healthy food which is natural and organic for
                    good health
                </p>
                <p>
                    Now, when you are taking care of your whole body, it requires a lot of nutrients like minerals, iron
                    and vitamins that keep you healthy and these minerals and vitamins, they’re not formed in your body,
                    they should be taken from your diet
                </p>
                <p>
                    A nutritionist believes that food is a medicine for the body as it helps you to prevent various
                    diseases with a properly balanced diet like obesity, diabetes, cancer, blood pressure, heart
                    diseases and many more
                </p>
                <p>
                    And also a nutritionist says that following holistic diet programs has many more health benefits
                    like strengthening the immune system, balancing blood sugar level, Losing weight, better sleep,
                    preventing diseases, best skin tone and the list goes on.
                </p>
                <p>
                    While you take care of Mental, physical, emotional and spiritual illness, to prevent these
                    anxieties, nutrition and a balanced diet is the key factor. However, having practice yoga will keep
                    you calm and fit mentally, having a practice of meditation will give you mental peace and many more
                </p>
                <p>
                    But if one should follow all of the above things, they need proper guidance who helps him or her in
                    understanding their body conditions.
                </p>
                <p>
                    Now, there are a lot of people who struggle in finding the proper diet program for themselves or
                    finding a proper regime.
                </p>
                <p>
                    So, if you’re one of them and find additional help? I’m here to help you out.
                </p>
                <p>
                    My clinic will help you in understanding your body needs and conditions and it will give you the
                    best diet plan program to achieve good health and well-being in life.
                </p>
            </>
        )
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div
            className={"program-detail-main-container"}
        >
            <div
                className={"headings-container flex-center"}
                style={{backgroundImage: `url(${currentProgram?.image})`}}
            >
                {/*<div className={"blurred-filter"}/>*/}
                <div className={"headings-inner-container"}>
                    <div className={"primary-heading"}>
                    {currentProgram?.label}
                </div>
                    <div className={"secondary-heading"}>
                    <span
                        className={"home-page-link"}
                        onClick={() => navigate("/")}
                    >
                        Home
                    </span> / {breadCrumb}
                </div>
                </div>
            </div>
            <div className={"content-container"}>
                <div
                    className={"content-row"}
                >
                    {/*<div className={"image-container"} style={{backgroundImage: `url(${currentProgram?.image})`}}/>*/}
                    <div className={"info-container"}>
                        {contentMap?.[currentProgram?.value]}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProgramDetailPage