import React, {useEffect, useRef, useState} from "react";
import {Col, Row} from "antd";
import {CalenderIcon, UserIcon} from "../Utils/svgs";

const StatsSection = () => {
    const counterRef = useRef(null);
    const [counterValue, setCounterValue] = useState(0);
    const [yearsCounter, setYearsCounter] = useState(0);

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5, // Adjust the threshold as needed
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    // Start the counter animation
                    const target = entry.target;
                    const startValue = 0;
                    const endValue = 600;
                    const duration = 2000; // Animation duration in milliseconds
                    const increment = Math.ceil((endValue - startValue) / (duration / 16));

                    let currentCount = startValue;
                    const interval = setInterval(() => {
                        currentCount += increment;
                        if (currentCount >= endValue) {
                            currentCount = endValue;
                            clearInterval(interval);
                        }
                        setCounterValue(currentCount);
                    }, 16);

                    const yearCounterStartValue = 0;
                    const yearCounterEndValue = 5;
                    const yearCounterDuration = 9000; // Animation duration in milliseconds
                    const yearCounterIncrement = Math.ceil((yearCounterEndValue - yearCounterStartValue) / (yearCounterDuration / 2));

                    let yearCounterCurrentCount = yearCounterStartValue;
                    const yearCounterInterval = setInterval(() => {
                        yearCounterCurrentCount += yearCounterIncrement;
                        if (yearCounterCurrentCount >= yearCounterEndValue) {
                            yearCounterCurrentCount = yearCounterEndValue;
                            clearInterval(yearCounterInterval);
                        }
                        setYearsCounter(yearCounterCurrentCount);
                    }, 300);


                    // Disconnect the observer once animation starts
                    observer.unobserve(target);
                }
            });
        }, options);

        if (counterRef.current) {
            observer.observe(counterRef.current);
        }

        return () => {
            if (counterRef.current) {
                observer.unobserve(counterRef.current);
            }
        };
    }, []);

    return (
        <div className={"stats-container"} ref={counterRef}>
            <Row className={"stats-data-row"} justify={"center"} gutter={[256, 35]} align={"middle"}>
                <Col className={"d-flex stats-col"}>
                    <div className={"icon-container flex-center"}>
                        <CalenderIcon fill={"#ffffff"} className={"stats-icon"}/>
                    </div>
                    <div className={"data-container"}>
                        <span className={"data-num"}>{yearsCounter}<span className={"plus-sign"}>+</span></span>
                        <span className={"data-desc"}>Years of practice</span>
                    </div>
                </Col>
                <Col className={"d-flex stats-col"}>
                    <div className={"icon-container flex-center"}>
                        <UserIcon fill={"#ffffff"} className={"stats-icon"}/>
                    </div>
                    <div className={"data-container"}>
                        <span className={"data-num"}>{counterValue}<span className={"plus-sign"}>+</span></span>
                        <span className={"data-desc"}>Happy patients</span>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default StatsSection