import CustomRoutes from "./Routing/Routes";
import "./Styles/index.scss";

function App() {
  return (
    <div className="App">
      <CustomRoutes />
    </div>
  );
}

export default App;
