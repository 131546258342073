import {Button, Col, Row} from "antd";
import {GraduateIcon} from "../Utils/svgs";
import IntroC1 from "../Assests/intro-c1.png";
import IntroC2 from "../Assests/intro-c2.png";
import IntroC3 from "../Assests/intro-c3.png";
import IntroC4 from "../Assests/intro-c4.png";
import IntroC5 from "../Assests/intro-c5.png";
import IntroC6 from "../Assests/intro-c6.jpg";

import {Swiper, SwiperSlide} from "swiper/react"
import "swiper/css"
import 'swiper/swiper.min.css'
import "swiper/css/pagination"
import {Pagination, Autoplay} from 'swiper'
import AboutSection from "../Components/About";
import StatsSection from "../Components/StatsSection";
import WhatWeDoSection from "../Components/WhatWeDoSection";
import OurProgrammesSection from "../Components/OurProgrammesSection";
import OrderProgram from "../Components/OrderProgram";
import LeaveReply from "../Components/LeaveReply";
import ReviewSection from "../Components/ReviewSection";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";

const HomePage = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const navigate = useNavigate()
    const carouselImages = [
        {
            image: IntroC1
        },
        {
            image: IntroC2
        },
        {
            image: IntroC3
        },
        {
            image: IntroC4
        },
        {
            image: IntroC5
        },
        {
            image: IntroC6
        },
    ]
    return (
        <div className={"home-page-main-container"}>
            <div className={"intro-container"}>
                <Row className={"content-main-row"}>
                    <Col lg={12} className={"headings-container flex-vertical-center"}>
                        <div className={"first-quote"}>
                            Let’s Nourish your body with healthy and colorful foods as your Eating matters who you
                            become.
                        </div>
                        <div className={"second-quote"}>
                            A Balanced Diet Works Wonders
                        </div>
                        <div className={"third-quote"}>
                            Perfectly balanced healthy nutrition programs by <span className={"owner-name"}>Tenzin Kelsang</span> –
                            certified nutritionist & lifestyle coach.
                        </div>
                        <Button
                            className={"about-Tenzin-button flex-center"}
                            onClick={() => navigate("/about-us")}
                        >
                            About Tenzin
                            <GraduateIcon className={"graduate-icon"}/>
                        </Button>
                    </Col>
                    <Col lg={12} xs={24} className={"carousel-container"}>
                        <Swiper
                            className="images-container"
                            loop={true}
                            speed={1000}
                            grabCursor={true}
                            spaceBetween={24}
                            pagination={{
                                clickable: true
                            }}
                            autoplay={{delay: 3000}}
                            breakpoints={{
                                576: {
                                    slidesPerView: 1
                                },
                                768: {
                                    slidesPerView: 1,
                                    spaceBetween: 48
                                }
                            }}
                            modules={[Pagination, Autoplay]}
                        >
                            {carouselImages?.map(({image}, idx) => {
                                return (
                                    <SwiperSlide key={idx}>
                                        <div style={{width: "100%", height: "100%"}}>
                                            <img src={image} alt="" className="carousel-image"/>
                                        </div>
                                    </SwiperSlide>
                                )
                            })}
                        </Swiper>
                    </Col>
                </Row>
            </div>
            <AboutSection />
            <StatsSection />
            <WhatWeDoSection />
            <OurProgrammesSection />
            <OrderProgram />
            <ReviewSection />
            <LeaveReply />
        </div>
    )
}

export default HomePage