import {useNavigate} from "react-router-dom";
import React, {useEffect} from "react";

const AboutUsPage = () => {
    const navigate = useNavigate()

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className={"about-us-page-container"}>
            <div className={"headings-container flex-center"}>
                <div className={"primary-heading"}>
                    About Us
                </div>
                <div className={"secondary-heading"}>
                    <span
                        className={"home-page-link"}
                        onClick={() => navigate("/")}
                    >
                        Home
                    </span> / About Us
                </div>
            </div>
            <div className={"content-main-container"}>
                <div className={"about-company-container mb-30"}>
                    <div className={"heading"}>
                        About Company
                    </div>
                    <div className={"content"}>
                        Tenzin Nutrition is a Health and Nutrition based company, established on July 6, 2021 with an objective
                        and
                        a mission to change people’s lifestyle, perception about health, and to prevent various
                        lifestyle-related disorders and diseases by spreading awareness about healthy eating and living
                        an
                        active life
                    </div>
                </div>
                <div className={"what-we-do-container mb-30"}>
                    <div className={"heading"}>
                        What We Do
                    </div>
                    <ul>
                        <li className={"content"}>
                            We provide clients with Nutrition counseling, educate them about food and nutrition, advise
                            on
                            the selection of ingredients, send out recipe documents, analyze nutrient deficiencies,
                            symptoms, and any other medical conditions by analyzing their blood reports and rectifying
                            their
                            deranged blood parameters by putting them on a therapeutic diet and also recommending them
                            nutrition-based supplements
                        </li>
                        <hr className={"horizontal-line"}/>
                        <li className={"content"}>
                            We also conduct individualized nutritional assessments, analyze the client’s eating habits,
                            and
                            outline the areas of improvement; building a customized Nutrition Plan, with follow-ups to
                            assess their progress
                        </li>
                        <hr className={"horizontal-line"}/>
                        <li className={"content"}>
                            Nutrition educational webinars and social media awareness is also a part of our program
                        </li>
                        <hr className={"horizontal-line"}/>
                    </ul>
                </div>
                <div className={"our-vision-container mb-30"}>
                    <div className={"heading"}>
                        Our Vision
                    </div>
                    <div className={"content"}>
                        Our Vision is to improve the quality of human life by educating the clients on their respective
                        nutritional needs and reduce the consumption of processed and unhealthy food, to prevent the
                        risk of
                        deadly diseases, to name a few Diabetes, Hepatitis, and obesity in our community
                    </div>
                </div>
                <div className={"about-tenzin-container mb-30"}>
                    <div className={"heading"}>
                        About Tenzin
                    </div>
                    <div className={"content"}>
                        <p>
                            Founder of Tenzin Nutrition, Miss Tenzin Kelsang is a professional dietitian with more
                            than 6 years of experience in serving clients from all walks of life. She holds diabetes
                            education certificate course from National Diabetes Education Program associated with
                            Dr. Mohan’s Diabetes Education Academy in India and has been diabetes educator where she
                            educates as well as serve people with personalised diet plan. In addition, she is also a
                            certified yoga trainer with completion of 500 hours of yoga teacher training course from
                            Vishwapoornima yoga Centre in Bangalore. She even did international certificate course
                            on cancer.
                            She has done a Master’s in Nutrition& Dietetics from J. SS University in Mysore,
                            Karnataka. She is basically from Tibet and has been staying in India for more than
                            decades. She strongly believes in the Hippocrates quote <strong>"Let’s food be thy
                            medicine and
                            let’s medicine be thy food"</strong>. Nutrition is only the fundamental part of the
                            body’s
                            excellence and has better well-being. Her expertise includes lifestyle management,
                            Weight loss, Weight gain, Muscle gain, therapeutic dietary management, Sports Nutrition,
                            Kids Nutrition, Nutrition for a new life, Nutrition for skin and hair, and various
                            medical conditions and lifestyle disorders. Moreover, she believes that eating healthy
                            foods and having physical exercise is not sufficient, besides, it’s imperative to have a
                            happy mind. Health is a relationship between your body, mind, and soul. She is an
                            extremely organized, professional, and self-motivated Dietitian with a great passion for
                            nutrition and people’s well-being.
                            Founder of Tenzin Nutrition, Miss Tenzin Kelsang is a professional dietitian with more
                            than 6 years of experience in serving clients from all walks of life. She holds diabetes
                            education certificate course from National Diabetes Education Program associated with
                            Dr. Mohan’s Diabetes Education Academy in India and has been diabetes educator where she
                            educates as well as serve people with personalised diet plan. In addition, she is also a
                            certified yoga trainer with completion of 500 hours of yoga teacher training course from
                            Vishwapoornima yoga Centre in Bangalore. She even did international certificate course
                            on cancer.
                            She has done a Master’s in Nutrition& Dietetics from J. SS University in Mysore,
                            Karnataka. She is basically from Tibet and has been staying in India for more than
                            decades. She strongly believes in the Hippocrates quote “Let’s food be thy medicine and
                            let’s medicine be thy food’’. Nutrition is only the fundamental part of the body’s
                            excellence and has better well-being. Her expertise includes lifestyle management,
                            Weight loss, Weight gain, Muscle gain, therapeutic dietary management, Sports Nutrition,
                            Kids Nutrition, Nutrition for a new life, Nutrition for skin and hair, and various
                            medical conditions and lifestyle disorders. Moreover, she believes that eating healthy
                            foods and having physical exercise is not sufficient, besides, it’s imperative to have a
                            happy mind. Health is a relationship between your body, mind, and soul. She is an
                            extremely organized, professional, and self-motivated Dietitian with a great passion for
                            nutrition and people’s well-being.
                        </p>
                        <p>
                            Her backgrounds include 4 years and one month worked in Qua Nutrition, 4months of
                            Register Dietitian internship in Apollo Hospital, Bangalore, and 1.2 years worked in
                            HealthifyMe and Nutrition Subject Matter Expert from 1st January 2023 till date at
                            Kadamba organization, Bangalore.
                            In addition to her qualification, she had done lots of certification courses on
                            Vitiligo, PCOD /PCOS, Thyroid, Gut health, Sports, and nutritional supplements for
                            various medical conditions to enhance her knowledge.
                        </p>
                        <p>
                            Her achievement includes four times, she has been awarded the monthly performer of the
                            employee in Qua Nutrition Company and has also won the yearly best performer for the
                            year 2018-2019 at the same company.
                            Therefore, Tenzin Kelsang serves her clients with holistic approaches with her expertise
                            in aforementioned fields.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutUsPage