import {Button, Col, Row} from "antd";
import {UserIcon} from "../Utils/svgs";

const WhatWeDoSection = () => {
    const scrollToTestimonials = () => {
        const targetElement = document.getElementById('review-section-container');
        if (targetElement) {
            targetElement.scrollIntoView({behavior: 'smooth',block: 'center'});
        }
    }
    return (
        <div className={"what-we-do-main-container"}>
            <Row className={"heading-row"}>
                <Col lg={24} xs={24} className={"first-heading flex-center"}>
                    What We Do
                </Col>
                <Col lg={24} xs={24} className={"primary-heading flex-center"}>
                    Guaranteed Results
                </Col>
            </Row>
            <Row className={"content-row"} gutter={20}>
                <Col className={"content-points"} lg={17}>
                    <ul>
                        <li>
                            We provide clients with Nutrition counseling, educate them about food and nutrition, advise
                            on the selection of ingredients, send out recipe documents, analyze nutrient deficiencies,
                            symptoms, and any other medical conditions by analyzing their blood reports and rectifying
                            their deranged blood parameters by putting them on a therapeutic diet and also recommending
                            them nutrition-based supplements
                        </li>
                        <hr/>
                        <li>
                            We also conduct individualized nutritional assessments, analyze the client’s eating habits,
                            and outline the areas of improvement; building a customized Nutrition Plan, with follow-ups
                            to assess their progress
                        </li>
                        <hr/>
                        <li>
                            Nutrition educational webinars and social media awareness is also a part of our program.
                        </li>
                        <hr/>
                        <li>
                            Our Vision is to improve the quality of human life by educating the clients on their
                            respective nutritional needs and reduce the consumption of processed and unhealthy food, to
                            prevent the risk of deadly diseases, to name a few Diabetes, Hepatitis, and obesity in our
                            community
                        </li>
                    </ul>
                </Col>
                <Col lg={7} md={24}>
                    <div className={"image-container"}>
                        <Row>
                            <Col lg={24} className={"primary-text"}>
                                Real people. Real results.
                            </Col>
                            <Col lg={24} className={"secondary-text"}>
                                Check out what clients say about my programs!
                            </Col>
                            <Col lg={24} xs={24} className={"flex-center"}>
                                <Button
                                    className={"testimonial-button flex-center"}
                                    onClick={scrollToTestimonials}
                                >
                                    Read testimonials
                                    <UserIcon fill={"#ffffff"} className={"user-icon"}/>
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default WhatWeDoSection