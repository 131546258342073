import {Button, Col, Form, Input, message, Row} from "antd";
import axios from "axios";
import {useState} from "react";

const OrderProgram = () => {
    const [selectedOption, setSelectedOption] = useState("weightLoss")
    const programsList = [
        {
            label: "Weight loss",
            value: "weightLoss"
        },
        {
            label: "Weight Gain",
            value: "weightGain"
        },
        {
            label: "Diabetes Management",
            value: "diabetesManagement"
        },
        {
            label: "Nutrition For Kids",
            value: "nutritionForKids"
        },
        {
            label: "Nutrition For New life",
            value: "newLife"
        },
        {
            label: "PCOS Program",
            value: "PCOS"
        },
        {
            label: "Nutrition For Sports",
            value: "nutritionForSports"
        },
        {
            label: "Nutrition For Skin and Hair",
            value: "SkinAndHair"
        },
        {
            label: "Blood test Interpretation",
            value: "bloodTest"
        },
    ]
    const {TextArea} = Input

    const handleFormSubmit = async (values) => {
        try {
            const response = await axios.post(`https://docs.google.com/forms/d/1nlkK1nDIE3aUg2Mm82qJjuWAZnJ4y_FApwJ-aZ52hk8/formResponse?entry.989622971=amankumar&entry.513962639_sentinel=mymail`,
                null
                //     ...values,
                //     "entry.644625596": selectedOption
                // })
            )
            message.success(response?.data?.message)
        } catch ({response}) {
            message.error(response?.data?.error)
        }
    }

    return (
        <div className={"order-program-main-container"}>
            <Row className={"heading-row"}>
                <Col lg={24} xs={24} className={"primary-heading flex-center"}>
                    <hr className={"backdrop-line"}/>
                    <span className={"heading-text"}>Improve your life</span>
                </Col>
                <Col lg={24} xs={24} className={"secondary-heading flex-center"}>
                    Order Program
                </Col>
            </Row>
            <Row style={{maxWidth: "100%"}} align={"middle"}>
                {/*<Col lg={12} xs={24} md={24}>*/}
                {/*    <Form*/}
                {/*        layout={"vertical"}*/}
                {/*        className={"form-container"}*/}
                {/*        onFinish={handleFormSubmit}*/}
                {/*    >*/}
                {/*        <Form.Item*/}
                {/*            label={"Name:"}*/}
                {/*            name={"entry.989622971"}*/}
                {/*            className={"form-label"}*/}
                {/*            rules={[*/}
                {/*                {*/}
                {/*                    required: true,*/}
                {/*                    message: "Please enter name",*/}
                {/*                },*/}
                {/*            ]}*/}
                {/*        >*/}
                {/*            <Input*/}
                {/*                placeholder={"John Brown"}*/}
                {/*            />*/}
                {/*        </Form.Item>*/}
                {/*        <Form.Item*/}
                {/*            label={"Email:"}*/}
                {/*            name={"entry.513962639_sentinel"}*/}
                {/*            className={"form-label"}*/}
                {/*            rules={[*/}
                {/*                {*/}
                {/*                    required: true,*/}
                {/*                    message: "Please enter email",*/}
                {/*                },*/}
                {/*            ]}*/}
                {/*        >*/}
                {/*            <Input*/}
                {/*                placeholder={"name@domain.com"}*/}
                {/*                type={"email"}*/}
                {/*            />*/}
                {/*        </Form.Item>*/}
                {/*        <Form.Item*/}
                {/*            label={"Phone Number:"}*/}
                {/*            name={"phone"}*/}
                {/*            className={"form-label"}*/}
                {/*            rules={[*/}
                {/*                {*/}
                {/*                    required: true,*/}
                {/*                    message: "Please enter phone number"*/}
                {/*                }*/}
                {/*            ]}*/}
                {/*        >*/}
                {/*            <Input*/}
                {/*                placeholder={"+123 456 78 90"}*/}
                {/*                type={"number"}*/}
                {/*            />*/}
                {/*        </Form.Item>*/}
                {/*        <div className={"program-label form-label"}>*/}
                {/*            Choose nutrition program:*/}
                {/*        </div>*/}
                {/*        <Row gutter={[16, 16]} className={"options-container"}>*/}
                {/*            {programsList?.map((item, idx) => (*/}
                {/*                <Col className={"get-hover"}>*/}
                {/*                    <span*/}
                {/*                        className={selectedOption === item?.value ? "program-item selected" : "program-item"}*/}
                {/*                        onClick={() => setSelectedOption(item?.value)}*/}
                {/*                    >*/}
                {/*                        {item?.label}*/}
                {/*                    </span>*/}
                {/*                </Col>*/}
                {/*            ))}*/}
                {/*        </Row>*/}
                {/*        <Form.Item*/}
                {/*            label={"Note:"}*/}
                {/*            name={"message"}*/}
                {/*            className={"form-label"}*/}
                {/*            rules={[*/}
                {/*                {*/}
                {/*                    required: true,*/}
                {/*                    message: "Please enter message",*/}
                {/*                },*/}
                {/*            ]}*/}
                {/*        >*/}
                {/*            <TextArea*/}
                {/*                placeholder={"Food allergies, special conditions etc."}*/}
                {/*                rows={4}*/}
                {/*            />*/}
                {/*        </Form.Item>*/}
                {/*        <Form.Item>*/}
                {/*            <Button*/}
                {/*                htmlType={"submit"}*/}
                {/*                className={"place-order-button flex-center"}*/}
                {/*            >*/}
                {/*                Place my order!*/}
                {/*            </Button>*/}
                {/*        </Form.Item>*/}
                {/*    </Form>*/}
                {/*</Col>*/}
                <Col lg={24} xs={24} className={"flex-center image-container-col"}>
                    <div className={"image-container"}>
                        <span className={"inner-quote"}>
                            It's time to eat good & feel good!
                        </span>
                        <span className={"msg-1"}>
                            Please Click on the button below to get in touch with us.
                        </span>
                        <Button className={"order-program-button"} size={"large"} onClick={() => window.open("https://docs.google.com/forms/d/e/1FAIpQLSfK96K5NIf4gg-7DRlIivnbfo0pRd2qyIDQ5wGEcNNjoHfrTw/viewform", "_blank")}>
                            Order Program
                        </Button>
                        <span className={"msg-2"}>
                            * This link will redirect to a google form. Please fill the required details.
                        </span>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default OrderProgram