import React, {useEffect, useRef, useState} from "react"
import userImg01 from "../Assests/userImg01.jpeg"
import userImg02 from "../Assests/userImg02.jpeg"
import defaultUserImg from "../Assests/defaultUserImg.png"
import testimonialImg1 from "../Assests/testimonial1.jpg"
import testimonialImg2 from "../Assests/testimonial2.jpg"
import userImg03 from "../Assests/testimonial3.jpg"
import userimg04 from "../Assests/testimonial4.jpeg"
import {Button, Col, Modal, Row} from "antd";

const ReviewSection = () => {
    const containerRef = useRef(null);
    const [reachedMaxScroll, setReachedMaxScroll] = useState(false);
    const [reachedZeroScroll, setReachedZeroScroll] = useState(true);
    const [selectedReview, setSelectedReview] = useState(null)
    const [showModal, setShowModal] = useState(false)
    const testimonials = [
        {
            image: userImg01,
            name: "Tenzin Choekyi",
            catchPhrase: "Lost 6kg in 3 months",
            description: "Earlier I don’t have any limit with my intake of food if I like something, which is unhealthy in certain level but This is the first time I feel satisfied; my cravings have diminished dramatically under the advice of Tenzin, and that helps me with reducing my weight of 6kg in few months."
        },
        {
            image: userImg02,
            name: "Tenzin Phentok",
            catchPhrase: "Health gains",
            description: "My journey with Dietician Tenzin Kelsang started two years ago when I consulted her regarding intermittent fasting and diet restrictions to start my healthy lifestyle. I have seen changes in me after I consulted her for the longest time period. She has a unique way of suggesting every possible way which could benefit every client’s health."
        },
        {
            image: userImg03,
            name: "Priya Kamari",
            catchPhrase: "PowerUp with Tenzin!",
            description: "I've joined Tenzin Nutrition on the Month of December 2023.Before joining her I was underweight and had so many problems regarding my immunity and stamina to stand on the field for long time. But after taking consultation from Tenzin mam. I felt the difference in only 2-3 days. I was not feeling tired and fatigued on the field. My energy level was good. And In 2-3 months I felt so much difference on my body. My body is responding much better on the field. And now I'm following the Tenzin mam diet program which is very much helpful for me and specially it is helping me enhancing my performance on the field. Thank you so much mam for giving me the best diet plan which has helped me a lot in increasing my performance."
        },
        {
            image: userimg04,
            name: "Snehal Kavthankar",
            catchPhrase: "Peak performance fuel",
            description: " a Ranji Trophy Player and I've been taking diet plans from Tenzin for over a year now. The entire season my body felt fit and fantastic due to the well planned, nutritional diet with lots of flexibility and variety for the palate. Tenzin was extremely cooperative, active and she is well versed with her skill. Would definitely recommend her services for overall development and growth."
        },
        {
            image: defaultUserImg,
            name: " Phurbu Dolma",
            catchPhrase: "Food for thought",
            description: "Well, I have started consulting certified dietician Tenzin after having long term skin allergy problems and also been not able to maintain a proper healthy diet. She has been very easy to talk to and share any detail information with her because she is very receptive and gives you many alternative suggestions and motivate your desire to change. I took her diet plan of three months and after that I can really see some major changes in my body. I did lose some amount of weight and most importantly my skin has improved way better than before. I completely lost my craving for sweets and soft drinks which I early often taken lightly. I am really thankful to Tenzin for trusting me and guiding me through this journey. I truly recommend anyone to consult her as it would change their mindset towards dietary."
        },
        {
            image: defaultUserImg,
            name: "Shalom Neeya",
            catchPhrase: "Revive. Thrive. Tenzin.",
            description: "My food habits were getting pretty bad as my diet mostly included junk and processed foods. I started to notice my health deteriorating as my hair started to fall out, got severe acne and gained weight. I also had high cholesterol levels, and vitamin deficiencies. I came across Tenzin's profile while searching for a dietitian and wrote to her on LinkedIn. I have also been very picky since childhood about eating my veggies and I'm very glad that Tenzin made sure to design the food charts based on my preferences. It is the best choice I've made for my health so far. With a balanced and nutrition filed diet plan made by Tenzin, I overcame all the parameters in my blood report that were off within 3 months. We also connected every week to check upon the improvements and make changes to the diet plan accordingly. My family also tried different diet plans like keto and vegan diets for weight loss but Tenzin's diet charts seemed more conventional and easier to follow. By the end of my subscription, I understood the importance of a healthy balanced diet and how it not only fixes your health but also your lifestyle. I totally recommend Tenzin Nutrition to all those who are looking forward to living that healthy lifestyle.",
            descImg: testimonialImg1
        },
        {
            image: defaultUserImg,
            name: "Nandish & Tejal Patel",
            catchPhrase: "Slim down success",
            description: "Tenzin Nutrition has helped me and my son a lot in maintaining our bodies as well as losing a lot of extra weight. Nandish lost 7.8 Kilos (from 87 kilos to 79.2 kilos) and reached his normal BMI within the 3 months whereas my weight decreased from 79.2 kilos to 70.4 kilos and reached normal BMI which is amazing. The personalized diet plan really made it easy to follow and the constant support from Tenzin has been awesome."
        },
        {
            image: defaultUserImg,
            name: "Sumit Sharma",
            catchPhrase: "Grateful for the gains!",
            description: "Hi this is Sumit Sharma and I just want to thank MS. Tenzin for helping me out in my journey to a better lifestyle, I've been going through severe diet problems which affected my overall health and after I joined Tenzin nutrition there have been significant changes in my health and I joined One-Month Nutrition for Weight gain and my weight increased from 65 kgs to 68 kg. I'd recommend everyone to join Tenzin nutrition as she's quite concerned about her clients and takes their problems seriously."
        },
        {
            image: defaultUserImg,
            name: " Tsundu Dolma",
            catchPhrase: "Shed lockdown pounds",
            description: "I am Tsundu Dolma, due to lockdown I have gained about 7 kgs, I have started workout to lose those extra weights. To be more successful in losing weight I tried Tenzin Nutrition. I would recommend everyone to consult Miss Tenzin la, she is very sincere and smart at her work. Her given diets are easy and effective. I have lost 4 kgs in a month with her diet and workout. Thank you, Tenzin, la."
        },
        {
            image: defaultUserImg,
            name: " Ngawang Nydoen",
            catchPhrase: "Clear skin triumph",
            description: "I’m Ngawang Nydoen currently living in New Delhi. I got to know Tenzin Nutrition from a mutual friend. I was suffering from a severe acne problem pigmentation. I did a 1-month diet plan starting October but it’s been 4 months I’m still continuing it and Miss Tenzin is still following up on me. I feel like it was the best decision I have ever taken following Tenzin Nutrition. She listens to your problem and will try to solve it as much as she can. Now it’s been 4 months my acne has completely gone but the marks are till there (I know it takes time to heal the wound). For the pigmentation I know it takes time to heal but I will not stop doing my diet.",
            descImg: testimonialImg2
        },
        {
            image: defaultUserImg,
            name: "Kyi Lhamo",
            catchPhrase: "Wellness wins within!",
            description: "I am very thankful for the guidance and help my nutritionist Tenzin provided me with a customized Nutrition plan, thrxough which I was able to make significant improvements in my overall health and well-being. I had bad gastrointestinal problems and also had a poor haemoglobin count in my body but all of these problems started to fade away little by little as I started to consult her and followed the detailed diet charts and consumed the probiotic provided by Tenzin Nutrition. Believe it or not, within a month my Haemoglobin count increased from 9 to 13 which led to improvement in many of my daily activities. I also made great progress with my weight by losing about 5 Kilos which is 61 kilos to 55.9 kilos and BMI reduced it from 25.72 to 23.39. All this further led to so many improvements in my liver profiles and vitamins count. For instance, due to low vitamin D in my body, I used to have knee and joint discomfort but these problems easily faded as I made progress. I know without Tenzin Nutrition's daily or weekly follow up calls, her sincere encouragement and approachable personality, all of this would not have been possible for me in a short period of time. Thank you"
        },
        {
            image: defaultUserImg,
            name: "Madhave Karthik",
            catchPhrase: "Weight loss triumph",
            description: "I was introduced to Tenzin in 2019. Since then she has been a part of my weight loss journey. With the pandemic around for two years, I had a great difficulty in controlling my eating habits as I was confined to home and with no exercise it became more of a challenge. Therefore, Tenzin changed the diet plan for me and helped me to cut down on calories. Then in the year 2021 in October I was detected with diabetes borderline but with Tenzin's nutritional diet plan, I was able to reduce my weight substantially and overcome the diabetes problem. I was highly impressed by her detailed diagnosis and solution for every deranged blood parameter in my health report. And the surprise took to another stage of admiration when I kicked off 4 Kilos in a month. From then onwards my faith in her diet plans strengthened and today I am free of most of the ailments. The best quality of Tenzin is she would be connected with you every week to check if one is having any challenges in following the diet plan and if so, she has a ready alternative that makes it very comfortable to adhere to her plans. Her diet chart includes daily calories intake, needed for a particular weight loss person and then she breaks down every meal with the required amount of food with balanced protein, fats and carbohydrates. This is a very scientific approach and I would highly recommend Tenzin to anyone who wishes to have a systematic, scientific customized mentor in their weight loss journey."
        },
    ]

    useEffect(() => {
        const container = containerRef.current;

        const handleScroll = () => {
            const maxScroll = container.scrollWidth - container.clientWidth;
            const currentScroll = container.scrollLeft;

            if (currentScroll >= maxScroll - 120) {
                setReachedMaxScroll(true);
            } else {
                setReachedMaxScroll(false);
            }

            if (currentScroll < 120) {
                setReachedZeroScroll(true)
            } else {
                setReachedZeroScroll(false)
            }
        };

        container?.addEventListener('scroll', handleScroll);

        return () => {
            container?.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div
            className={"review-section-main-container"}
            id={"review-section-container"}
        >
            <Row className={"heading-row"}>
                <Col lg={24} xs={24} className={"secondary-heading flex-center"}>
                    What Our Happy Clients Say
                </Col>
            </Row>
            <div className={"outer-container"}>
                <div
                    className={"inner-container"} ref={containerRef}>
                    {
                        testimonials?.map((item, idx) => (
                            <div
                                className={"item-container"}
                            >
                                <div className={"image-container"}>
                                    <img src={item?.image} alt={"user"}/>
                                </div>
                                <div className={"reviewer-name"}>
                                    {item?.name}
                                </div>
                                <div className={"catch-phrase-container"}>
                                    {item?.catchPhrase}
                                </div>
                                <div
                                    onClick={() => {
                                        setSelectedReview(item)
                                        setShowModal(true)
                                    }}
                                >
                                    <Button
                                        className={"know-more-button d-flex"}
                                    >
                                        Know More
                                    </Button>
                                </div>
                            </div>
                        ))
                    }
                </div>
                {!reachedMaxScroll && <div className={"blurred-edge"}/>}
                {!reachedZeroScroll && <div className={"blurred-edge left"}/>}
                <Modal
                    open={showModal}
                    onCancel={() => setShowModal(false)}
                    footer={false}
                    className={"review-modal-container"}
                >
                    <div className={"modal-content-container"}>
                        <div className={"images-container"}>
                            <img src={selectedReview?.image} alt={"user"}/>
                        </div>
                        <div className={"name-container"}>
                            {selectedReview?.name}
                        </div>
                        <div className={"description-container"}>
                            {selectedReview?.description}
                        </div>
                        <div className={"description-image-container"}>
                            {selectedReview?.descImg &&
                                <img src={selectedReview?.descImg} alt={"results"} />
                            }
                        </div>
                    </div>
                </Modal>
            </div>
        </div>
    )
}

export default ReviewSection