import Op01Img from "../Assests/op01.png"
import Op02Img from "../Assests/op02.jpg"
import Op03Img from "../Assests/op03.png"
import Op04Img from "../Assests/op04.jpg"
import Op05Img from "../Assests/op05.png"
import Op06Img from "../Assests/op06.png"
import Op07Img from "../Assests/op07.jpeg"
import Op08Img from "../Assests/op08.jpg"
import Op09Img from "../Assests/op09.jpg"
import Op10Img from "../Assests/op10.jpeg"

export const programsList = [
    {
        label: "Weight Loss Program",
        value: "weight-loss-program",
        image: Op01Img
    },
    {
        label: "Diabetes Management Program",
        value: "diabetes-management-program",
        image: Op02Img
    },
    {
        label: "PCOD/PCOS Diet Management",
        value: "pcod-pcos-diet-management",
        image: Op03Img
    },
    {
        label: "Nutrition For Sports",
        value: "nutrition-for-sports",
        image: Op04Img
    },
    {
        label: "Skin and Hair Program",
        value: "skin-and-hair-program",
        image: Op05Img
    },
    {
        label: "Nutrition For Kids",
        value: "nutrition-for-kids",
        image: Op06Img
    },
    {
        label: "Nutrition For New Life",
        value: "nutrition-for-new-life",
        image: Op07Img
    },
    {
        label: "Nutrition For Weight Gain",
        value: "nutrition-for-weight-gain",
        image: Op08Img
    },
    {
        label: "Blood Test Interpretation",
        value: "blood-test-interpretation",
        image: Op09Img,
    },
    {
        label: "Holistic Health Program",
        value: "holistic-health-program",
        image: Op10Img
    },
]