import React, {useEffect, useState} from "react";
import {Row, Col, Drawer, Dropdown} from "antd";
import {
    CloseIcon,
    FacebookIcon,
    InstagramIcon,
    LinkedInIcon,
    MenuIcon,
} from "../Utils/svgs";
import {useNavigate} from "react-router-dom";
import {programsList} from "../data/programsList";
import logoIMG from "../Assests/logo.png"

const Header = () => {
    const [scrolled, setScrolled] = useState(false);
    const [expandMobileHeader, setExpandMobileHeader] = useState(false)
    const [isProgrammesListExpanded, toggleIsProgrammesListExpanded] = useState(false)
    const [selectedMobileHeader, setSelectedMobileHeader] = useState(null)
    const [redirectUrl, setRedirectUrl] = useState("")
    const navigate = useNavigate()

    useEffect(() => {
        const handleScroll = () => {
            const isScrolled = window.scrollY > 0;
            setScrolled(isScrolled);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        if (redirectUrl.length > 0) {
            navigate(`program-detail/${redirectUrl}`)
        }
    }, [redirectUrl])

    const headerItems = [
        {
            name: "Home",
            value: "home",
            link: "/"
        },
        {
            name: "About Us",
            value: "aboutUs",
            link: "about-us/"
        },
        {
            name: "Nutrition Programs",
            value: "nutritionPrograms",
            link: "#"
        },
        {
            name: "Yoga Course",
            value: "yogaCourse",
            link: "yoga-course/"
        },
        {
            name: "Gallery",
            value: "gallery",
            link: "gallery/"
        },
        {
            name: "Contact Us",
            value: "contactUs",
            link: "contact-us/"
        },
    ]
    const socialMediaIcons = [
        {
            name: "facebook",
            icon: <FacebookIcon fill={"#a5d269"} className={"social-media-icon"}/>,
            link: "https://www.facebook.com/tenzinnutrition"
        },
        {
            name: "linkedIn",
            icon: <LinkedInIcon fill={"#a5d269"} className={"social-media-icon"}/>,
            link: "https://www.linkedin.com/in/tenzin-kelsang-dietitian-1078b2136/"
        },
        {
            name: "instagram",
            icon: <InstagramIcon fill={"#a5d269"} className={"social-media-icon"}/>,
            link: "https://instagram.com/tenzinnutrition?igshid=ZGUzMzM3NWJiOQ=="
        },
    ]
    const dropdownItems = programsList?.map((item, idx) => ({
        key: item?.value,
        label: item?.label
    }))
    const handelMenuItemClicked = (e) => {
        setRedirectUrl(e?.key)
    };

    return (
        <>
            <div className={scrolled ? "header-main-container scrolled" : "header-main-container"}>
                <Row className={"items-container"} align={"middle"} gutter={30}>
                    <Col className={"logo-container"}>
                        <img src={logoIMG} alt={"log"} onClick={() => navigate("/")}/>
                    </Col>
                    {headerItems?.map((item, idx) => {
                        if (item?.value === "nutritionPrograms") {
                            return (
                                <Col onClick={() => navigate(item?.link)}>
                                    <Dropdown
                                        menu={{
                                            items: dropdownItems,
                                            onClick: handelMenuItemClicked
                                        }}
                                        overlayClassName={"header-dropdown-main-container"}
                                    >
                                        <span className={"header-item get-hover"}>
                                            {item?.name}
                                        </span>
                                    </Dropdown>
                                </Col>
                            )
                        } else return (
                            <Col onClick={() => navigate(item?.link)}>
                                <span className={"header-item get-hover"}>
                                    {item?.name}
                                </span>
                            </Col>
                        )
                    })}
                </Row>
                <Row className={"social-icons-container"} align={"middle"} gutter={6}>
                    {socialMediaIcons?.map((item, idx) => (
                        <Col>
                        <span
                            className={"icon-container flex-center get-hover"}
                            onClick={() => window.open(item?.link, '_blank')}
                        >
                            {item?.icon}
                        </span>
                        </Col>
                    ))}
                </Row>
            </div>
            <div className='header-main-container-mobile flex-center'>
                <Col className={"logo-container"}>
                    <img src={logoIMG} alt={"log"} onClick={() => navigate("/")}/>
                </Col>
                <Row gutter={[0, 16]} justify={"end"}>
                    <Col>
                        {/*<WebsiteIcon className="websiteIcon"/>*/}
                        <MenuIcon
                            fill={"#a5d269"}
                            onClick={() => setExpandMobileHeader(true)}
                            className={"menu-icon"}
                        />
                    </Col>
                </Row>
            </div>
            <Drawer
                open={expandMobileHeader}
                closable={false}
                className="mobile-expanded-header"
                width="90%"
            >
                <Row justify={"end"}>
                    <CloseIcon
                        className="close-button"
                        onClick={() => setExpandMobileHeader(false)}
                        fill={"#a5d269"}
                    />
                </Row>
                <Row gutter={[0, 32]}>
                    {headerItems.map((item, idx) => {
                        if (item?.value === "nutritionPrograms") {
                            return (
                                <Col lg={24} xs={24}>
                                    <div
                                        className={selectedMobileHeader === item?.value ? "header-item selected flex-vertical-center" : "header-item flex-vertical-center"}
                                        onClick={() => {
                                            setSelectedMobileHeader(item?.value)
                                            toggleIsProgrammesListExpanded(!isProgrammesListExpanded)
                                        }}
                                    >
                                    <span>
                                        {item?.name}
                                    </span>
                                    </div>
                                    <Row
                                        className={isProgrammesListExpanded ? "programmes-list" : "programmes-list compressed"}>
                                        {dropdownItems?.map((item, idx) => {
                                            return (
                                                <Col
                                                    xs={24}
                                                    className={"list-item"}
                                                    onClick={() => {
                                                        handelMenuItemClicked({key: item?.key})
                                                        setSelectedMobileHeader(null)
                                                        setExpandMobileHeader(false)
                                                        toggleIsProgrammesListExpanded(false)
                                                    }}
                                                >
                                                    {item?.label}
                                                </Col>
                                            )
                                        })}
                                    </Row>
                                </Col>
                            )
                        } else return (
                            <Col lg={24} xs={24}>
                                <span
                                    className={"header-item "}
                                    onClick={() => {
                                        navigate(`${item?.link}`)
                                        setSelectedMobileHeader(null)
                                        setExpandMobileHeader(false)
                                        toggleIsProgrammesListExpanded(false)
                                    }}
                                >
                                    {item?.name}
                                </span>
                            </Col>
                        )
                    })}
                </Row>
                <Row className={"social-icons-container"} align={"middle"} gutter={6}>
                    {socialMediaIcons?.map((item, idx) => (
                        <Col>
                        <span
                            className={"icon-container flex-center get-hover"}
                            onClick={() => window.open(item?.link, '_blank')}
                        >
                            {item?.icon}
                        </span>
                        </Col>
                    ))}
                </Row>
            </Drawer>
        </>
    )
}

export default Header