import React from "react";
import {Route, Routes} from 'react-router-dom';
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import HomePage from "../Pages/HomePage";
import {UpArrowIcon} from "../Utils/svgs";
import AboutUsPage from "../Pages/AboutUsPage";
import ContactUsPage from "../Pages/ContactUsPage";
import ProgramDetailPage from "../Pages/ProgramDetailPage";
import GalleryPage from "../Pages/GalleryPage";
import YogaPage from "../Pages/Yoga";

const CustomRoutes = () => {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }

    return (
        <div className={"App-main-container"}>
            <Header/>
            <div className={"content-container"}>
                <Routes>
                    <Route path={"/"} element={<HomePage/>}/>
                    <Route path={"about-us/"} element={<AboutUsPage />} />
                    <Route path={"contact-us/"} element={<ContactUsPage />} />
                    <Route path={"program-detail/:id/"} element={<ProgramDetailPage />} />
                    <Route path={"gallery/"} element={<GalleryPage />} />
                    <Route path={"yoga-course/"} element={<YogaPage />} />
                </Routes>
            </div>
            <div className={"scroll-to-top-container get-hover flex-center"} onClick={scrollToTop}>
                <UpArrowIcon fill={"#ffffff"} className={"arrow-icon"}/>
            </div>
            <Footer/>
        </div>
    )
}

export default CustomRoutes