import React, {useEffect} from "react";
import {Button, Col, Row} from "antd";
import TenzinImg from "../Assests/about-img1.jpeg"
import {CheckListIcon, FacebookIcon, InstagramIcon} from "../Utils/svgs";
import {useNavigate} from "react-router-dom";

const AboutSection = () => {
    const navigate = useNavigate()
    return (
        <div className={"about-section-main-container"}>
            <Row align={"middle"}>
                <Col lg={12} xs={24} className={"img-container"}>
                    <div>
                        <img src={TenzinImg} alt={"tenzin-img"} className={"tenzin-img"}/>
                    </div>
                </Col>
                <Col lg={12} className={"details-container"}>
                    <Row className={"details-inner-row"}>
                        <Col lg={24} md={24} className={"about-name"}>
                            Miss.Tenzin Kelsang
                        </Col>
                        <Col lg={24} md={24} className={"about-designation"}>
                            Certified Nutritionist
                        </Col>
                        <Col lg={24} className={"about-description"}>
                            <p>
                                Founder of Tenzin Nutrition, Miss Tenzin Kelsang is a professional nutritionist with
                                more
                                than 6 years of experience in serving clients from all walks of life. She holds diabetes
                                education certificate course from National Diabetes Education Program associated with
                                Dr.
                                Mohan’s Diabetes Education Academy in India and has been diabetes educator where she
                                educates as well as serve people with personalised diet plan. In addition, she is also a
                                certified yoga trainer with completion of 500 hours of yoga teacher training course from
                                Vishwapoornima yoga Centre in Bangalore. She even did international certificate course
                                on
                                cancer.
                            </p>
                            <p>
                                She has done a Master’s in Nutrition& Dietetics from J. SS University in Mysore,
                                Karnataka.
                                She is basically from Tibet and has been staying in India for more than decades. She
                                strongly believes in the Hippocrates quote “Let’s food be thy medicine and let’s
                                medicine be
                                thy food’’. Nutrition is only the fundamental part of the body’s excellence and has
                                better
                                well-being. Her expertise includes lifestyle management, Weight loss, Weight gain,
                                Muscle
                                gain, therapeutic dietary management, Sports Nutrition, Kids Nutrition, Nutrition for a
                                new
                                life, Nutrition for skin and hair, and various medical conditions and lifestyle
                                disorders.
                                Moreover, she believes that eating healthy foods and having physical exercise is not
                                sufficient, besides, it’s imperative to have a happy mind. Health is a relationship
                                between
                                your body, mind, and soul. She is an extremely organized, professional, and
                                self-motivated
                                Dietitian with a great passion for nutrition and people’s well-being.
                            </p>
                            <p>
                                Her backgrounds include 4 years and one month worked in Qua Nutrition, 4months of
                                Register
                                Dietitian internship in Apollo Hospital, Bangalore, and 1.2 years worked in HealthifyMe
                                and
                                Nutrition Subject Matter Expert from 1st January 2023 till date at Kadamba organization,
                                Bangalore.
                                In addition to her qualification, she had done lots of certification courses on
                                Vitiligo,
                                PCOD /PCOS, Thyroid, Gut health, Sports, and nutritional supplements for various medical
                                conditions to enhance her knowledge.
                            </p>
                            <p>
                                Her achievement includes four times, she has been awarded the monthly performer of the
                                employee in Qua Nutrition Company and has also won the yearly best performer for the
                                year
                                2018-2019 at the same company.
                                Therefore, Tenzin Kelsang serves her clients with holistic approaches with her expertise
                                in
                                aforementioned fields.
                            </p>

                        </Col>
                        <Col className={"buttons-container d-flex"}>
                            <Button
                                className={"about-section-button filled flex-center"}
                                onClick={() => navigate("about-us/")}
                            >
                                Know More
                                <CheckListIcon fill={"#ffffff"} className={"action-button-icon"}/>
                            </Button>
                            <Button
                                className={"about-section-button flex-center"}
                                onClick={() => window.open("https://www.facebook.com/tenzinnutrition", "_blank")}
                            >
                                Facebook
                                <FacebookIcon fill={"rgb(123, 181, 25)"} className={"action-button-icon"}/>
                            </Button>
                            <Button
                                className={"about-section-button flex-center"}
                                onClick={() => window.open("https://instagram.com/tenzinnutrition?igshid=ZGUzMzM3NWJiOQ==", "_blank")}
                            >
                                Instagram
                                <InstagramIcon fill={"rgb(123, 181, 25)"} className={"action-button-icon"}/>
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    )
}

export default AboutSection