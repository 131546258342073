import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {Button, Col, Form, Input, Row} from "antd";
import {ClockIcon, MailIcon, PhoneIcon} from "../Utils/svgs";

const ContactUsPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const navigate = useNavigate()
    const {TextArea} = Input
    return (
        <div className={"contact-us-page-container"}>
            <div className={"headings-container flex-center"}>
                <div className={"primary-heading"}>
                    Contact Us
                </div>
                <div className={"secondary-heading"}>
                    <span
                        className={"home-page-link"}
                        onClick={() => navigate("/")}
                    >
                        Home
                    </span> / About Us
                </div>
            </div>
            <Row style={{maxWidth: "100%", margin: "50px 0 70px"}} align={"middle"}>
                <Col lg={24} xs={24} className={"flex-center image-container-col"}>
                    <div className={"image-container"}>
                        <span className={"inner-quote"}>
                            It's time to eat good & feel good!
                        </span>
                        <span className={"msg-1"}>
                            Please Click on the button below to get in touch with us.
                        </span>
                        <Button className={"order-program-button"} size={"large"} onClick={() => window.open("https://docs.google.com/forms/d/e/1FAIpQLSfK96K5NIf4gg-7DRlIivnbfo0pRd2qyIDQ5wGEcNNjoHfrTw/viewform", "_blank")}>
                            Order Program
                        </Button>
                        <span className={"msg-2"}>
                            * This link will redirect to a google form. Please fill the required details.
                        </span>
                    </div>
                </Col>
            </Row>
        </div>
    )
}


export default ContactUsPage