import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {Button, Col, Form, Input, Row} from "antd";
import YogaImg1 from "../Assests/yoga1.jpeg";
import YogaImg2 from "../Assests/yoga2.jpg";
import YogaExp1 from "../Assests/yogaExp1.png"
import YogaExp2 from "../Assests/yogaExp2.jpg"
import YogaExp3 from "../Assests/yogaExp3.png"
import YogaExp4 from "../Assests/yogaExp4.png"


const YogaPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const navigate = useNavigate()
    const {TextArea} = Input
    return (
        <div className={"yoga-page-container"}>
            <div className={"headings-container flex-center"}>
                <div className={"primary-heading"}>
                    Yoga Course
                </div>
                <div className={"secondary-heading"}>
                    <span
                        className={"home-page-link"}
                        onClick={() => navigate("/")}
                    >
                        Home
                    </span> / Yoga Course
                </div>
            </div>
            <div className={"content-container"}>
                <Row className={"content-main-row"} gutter={[30, 40]}>
                    <Col lg={12} md={24} xs={24}>
                        <div className={"image-container d-flex"}>
                            <img src={YogaImg1} alt={"yoga"}/>
                        </div>
                    </Col>
                    <Col lg={12} md={24} xs={24}>
                        <div className={"content-container yoga-content"}>
                            <div className={"content-sub-heading"}>
                                Brief History of Yoga
                            </div>
                            <p>
                                The word Yoga is derived from the Sanskrit word which translates as' ‘YOKE’’ or
                                ‘’UNION’’. Term “Yoke” means to draw or bind or unite together. This binding of mind,
                                body, soul and universal consciousness as union. It is the sole purpose of yoga. It is a
                                like a mind and body which can be trained to build overall bodily, mentally and
                                spiritual strength and flexibility.
                            </p>
                            <p>
                                It has become a global beloved and esteemed part of physical exercise in maintaining
                                healthy lifestyle over the last several decades. This practice started some thousand
                                years ago from the East has evolved into becoming the integral part of modern lifestyle.
                                On top of this, now a days, millions pursued yoga as the healthy exercise and spiritual
                                practice.
                            </p>
                            <p>
                                Additionally, modern yoga is most commonly associated with the practice of Asana, a
                                series of posture. It is one which helps to build strength, stamina, flexibility,
                                coordination, balance, and also to induce relaxation. Despite these benefits, Anasa is
                                just a fragment of Yoga practice.
                            </p>
                            <p>
                                Among the subtype of Yoga, Patanjali’s Yoga is broader as it provides and build the
                                foundation of yoga. There is a saying, “You can’t build a great building on a weak
                                foundation” by Gordon B. Hinckley. Thus this type of yoga contains far greater benefits
                                then just Asanas.
                            </p>
                            <p>
                                This is written in Patanjali Yoga Sutra which encompasses an eightfold path of the
                                practice, which is called ‘Eight Limbs of Yoga’. This sutra provides a guide in creating
                                a union between body, mind and spirit. Each of the Eight Limbs offers a means of living
                                with more integrity, self-discipline, respect for nature and connection with the
                                spiritual aspects of life. These eight practices are intended to be carried out in a
                                holistic and integrative manners.
                            </p>
                            <p>
                                To state some of benefits in practicing yoga and becoming it as your habit include
                                uplifting your health. Such as better digestion, relieving muscle pains(back, joints),
                                calming your sensitivity toward arthritis. It aids in strengthening your heart and lung
                                capacity. Aside from that, it reduces and calm the inflammation of your body, induce a a
                                quality sleep, of course boost stamina, strength, flexibility. Just icing of the cake,
                                it also reduce the stress and ameliorate your mental health.
                            </p>
                        </div>
                    </Col>
                    <Col lg={12} md={24} xs={24}>
                        <div className={"content-container yoga-content"}>
                            <div className={"content-sub-heading"}>
                                Why Do You Need a Yoga Teacher / Trainer?
                            </div>
                            <p>
                                Doing yoga is very different from regular exercise that you do. When it comes to yoga,
                                it is extremely important to understand the process and sequence of each yoga pose along
                                with its breathing pattern. Moreover, there are different methods and rules of doing
                                yoga. Therefore, it is extremely important to have a yoga trainer. Other benefits of
                                private yoga classes include one -on -one instruction that can be given with
                                personalised or curated to your pace and comfort, thus assisting with your unique needs
                                and goals. It also helps to prevent doing wrong posture, breathing technique, prevent
                                injury. Private sessions can help you develop a deeper connection with your practice and
                                a greater sense of self-awareness and understanding of level.
                            </p>
                        </div>
                    </Col>
                    <Col lg={12} md={24} xs={24}>
                        <div className={"image-container d-flex"}>
                            <img src={YogaImg2} alt={"yoga"}/>
                        </div>
                    </Col>
                </Row>
                <Row className={"experience-container"}>
                    <Col lg={24} md={24} xs={24} className={"content-sub-heading"}>
                        My brief yoga experiences
                    </Col>
                    <Col lg={24} md={24} xs={24} className={"experience-col"}>
                        <div className={"inner-container"}>
                            <div className={"experience-content center-align-text"}>
                                Work with people who have severe or mild back pain issues
                            </div>
                            <div className={"middle-container"}>
                                <div className={"round"}/>
                                <div className={"v-line"}/>
                            </div>
                            <div className={"experience-content center-align-text"}>
                                <img src={YogaExp1} alt={"tenzin-img"} className={"exp-image"}/>
                            </div>
                        </div>
                    </Col>
                    <Col lg={24} md={24} xs={24} className={"experience-col"}>
                        <div className={"inner-container"}>
                            <div className={"experience-content center-align-text"}>
                                Handle people who are suffering from varicose vein, arthritis and rheumatoid arthritis.
                            </div>
                            <div className={"middle-container"}>
                                <div className={"round"}/>
                                <div className={"v-line"}/>
                            </div>
                            <div className={"experience-content center-align-text"}>
                                <img src={YogaExp2} alt={"tenzin-img"} className={"exp-image"}/>
                            </div>
                        </div>
                    </Col>
                    <Col lg={24} md={24} xs={24} className={"experience-col"}>
                        <div className={"inner-container"}>
                            <div className={"experience-content center-align-text"}>
                                Deal with people who are having thyroid issues and having thyroidectomy surgery.
                                Moreover, other medical conditions including Diabetes, Hypertension, Hypotension, PCOS,
                                Migraine etc.
                            </div>
                            <div className={"middle-container"}>
                                <div className={"round"}/>
                                <div className={"v-line"}/>
                            </div>
                            <div className={"experience-content center-align-text"}>
                                <img src={YogaExp3} alt={"tenzin-img"} className={"exp-image"}/>
                            </div>
                        </div>
                    </Col>
                    <Col lg={24} md={24} xs={24} className={"experience-col"}>
                        <div className={"inner-container"}>
                            <div className={"experience-content center-align-text"}>
                                Collaborated with individuals spanning diverse age brackets, from those in their twenties to individuals in their fifties and sixties.
                            </div>
                            <div className={"middle-container"}>
                                <div className={"round"}/>
                                <div className={"v-line"}/>
                            </div>
                            <div className={"experience-content center-align-text"}>
                                <img src={YogaExp4} alt={"tenzin-img"} className={"exp-image"}/>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}


export default YogaPage